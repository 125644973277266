import { CSSProperties } from "react"
import { css, keyframes } from "styled-components"

const progress = keyframes`
    from { transform: translate(0px, -50px) scale(.6); }
    to { transform: translate(0px, 0px) scale(1); }
`

export const moveHomeCar = (
    duration: CSSProperties["animationDuration"], 
    repeat: CSSProperties["animationIterationCount"]
) => css`
    animation: ${progress} ${duration} ${repeat};
`