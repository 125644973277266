import styled from "styled-components"
import { WithCategory } from "../../types"
import { breakpoint, theme } from "../../styles"
import { getSvgById } from "../../utils"

/** Icon with parametrable color for advice button */
export const AdviceIcon = styled.div<WithCategory>`
    background-color: ${props => theme[props.category].advice};
    mask-image: url(${getSvgById("message").src});
    mask-repeat: no-repeat;
    aspect-ratio: 1.271656756;
    width: 56px;
    height: 50px;
    margin: 0 auto 5px auto;
    @media (max-width: ${breakpoint}px) {
        width: 9vw;
        height: 7vw;
    }
`