import { CSSProperties } from "react"
import { css, keyframes } from "styled-components"
// import {} from "styled-components/cssprop"

/**
 * Animates sprite animation
 * Formula: (steps * 100) / (steps - 1)
 * "steps - 1" => First step is 0
 */
export const spriteChanges = (steps: number) => keyframes`
    from { object-position: 0px 0%; }
    to { object-position: 0px ${(steps * 100) / (steps - 1)}%; }
`

interface Sprites {
    steps: number
    repeat: CSSProperties["animationIterationCount"]
    duration: CSSProperties["animationDuration"]
}


export const manageSpriteSteps = ({
    steps,
    duration = "5s",
    repeat = "infinite", 
}: Sprites) => css`animation: ${spriteChanges(steps)} ${duration} steps(${steps}) ${repeat};`