import styled, { css } from "styled-components"
import { theme, breakpoint } from "../../styles"

interface DetailsWrapperProps {
    label: string
    color: string
}

/** UI element displayed in Details views wrapping page content */
export const DetailsWrapper = styled.article<DetailsWrapperProps>`
    margin-bottom: 3%;
    padding: 3% 3% 3% 6%;
    width: 69%;
    background-color: ${theme.detailsBox.wrapper};
    transform: translateX(180px);

    @media (max-width: ${breakpoint}px) {
        padding: 5vw;
        margin: 19vw 5vw 3vw 5vw;
        width: auto;
        transform: translateX(0);
        ${props => css`
            &::before {
                content: "${props.label}";
                display: inline-block;
                position: relative;
                text-align: right;
                font-weight: bold;
                font-size: 2.5vw;
                width: 100%;
                right: 0;
                top: -2vw;
                text-transform: uppercase;
                color: ${props.color};
            }
        `}
    }
`