import styled from "styled-components"
import { breakpoint } from "../../styles"
import { _font_size } from "../../styles/mixins"

interface QuoteProps {
    color: string
}

/** Displays a quote in Details views header section */
export const Quote = styled.q<QuoteProps>`
    position: relative;
    max-width: 65%;
    display: inline-block;
    &:not(:last-of-type) {
        display: block;
        padding-bottom: 5px;
        margin-bottom: 5px;
        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            width: 90px;
            border-bottom: 2px solid ${props => props.color};
        }
    }
    font-family: Gotham;
    font-style: italic;
    ${_font_size(30)};

    
    @media (max-width: ${breakpoint}px) {
        font-size: 5vw;
    }

    padding: 0 5px 5px 0;
    letter-spacing: 1px;
    white-space: pre-line;
`