import { FC, ReactElement } from "react"
import { HomeButton } from "../molecules/HomeButton";
import { BackspaceButton } from "../molecules/BackspaceButton";
import { DetailsTypeButton } from "../molecules/DetailsTypeButton";
import styled, { css } from "styled-components";
import { _flexbox } from "../../styles/mixins";
import { breakpoint } from "../../styles";
import { WithCategory, NavButton } from "../../types";
import { Balloon, BalloonProps } from "../molecules/Balloon"
import { useMobileView } from "../../hooks";
import { Bubble } from "../molecules/Bubble";

interface NavRibbonProps extends WithCategory {
    buttons?: NavButton[],
    balloons?: BalloonProps[],
}

/** UI component which displays navigation elements on views */
export const NavRibbon: FC<NavRibbonProps> = ({
    category,
    buttons = undefined,
    balloons = undefined,
}): ReactElement => {
    const isMobileView = useMobileView()

    return (
        <Ribbon>
            {
                isMobileView && (
                    <Bubble 
                        category={category} 
                        position={{
                            customTransform: ["0px","0px"],
                            x: "3vw",
                            y: "3vw",
                        }}
                    />
                )
            }
            <List isOnScene={Boolean(balloons)}>
                {
                    !isMobileView && buttons && buttons.map(
                        ({ label, link }) => (
                            <DetailsTypeButton
                                key={link}
                                link={link}
                                category={category}
                                onClick={() => {
                                    //@ts-ignore
                                    OnCategoryPageEvent(link);
                                }}
                            >
                                {label}
                            </DetailsTypeButton>
                        )
                    )
                }

                {
                    balloons && balloons.map(
                        ({ label, link, x, y, size }) => (
                            <Item key={link}>
                                <Balloon
                                    label={label}
                                    link={link}
                                    category={category}
                                    size={size}
                                    x={x}
                                    y={y}
                                />
                            </Item>
                        )
                    )
                }

                <HomeButton category={category} />
                {buttons && <BackspaceButton category={category} />}
            </List>
        </Ribbon>
    )
}

/** Styled components */

const Ribbon = styled.nav`
    width: 100%;
    padding: 30px;
    z-index: 200;
    @media (max-width: ${breakpoint}px) {
        padding: 4vw;
    }
`

const List = styled.ul<{isOnScene: boolean}>`
    ${_flexbox({ jc: "flex-end" })}
    height: 50px;
    gap: 15px;
    @media (max-width: ${breakpoint}px) {
        gap: 3.8vw;
        ${props => props.isOnScene && css`
            ${_flexbox({ 
                fd: "column", 
                ai: "flex-end" 
            })}
            height: auto;
            & > :last-child {
                    margin-bottom: 28vw;
                }
        `}
    }
`

const Item = styled.li`
        @media (max-width: ${breakpoint}px) {
        margin-right: 0;
        width: 100%;
    }
`