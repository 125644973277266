import { FC, ReactElement } from "react"
import { Category } from "../../types"
import { NavButton } from "../atoms/NavButton"
import { HomeIcon } from "../atoms/HomeIcon"
import { ROUTES } from "../../_config/routes"

interface HomeButtonProps {
    category: Category
}

/** UI button used to go back to app root */
export const HomeButton: FC<HomeButtonProps> = ({
    category
}): ReactElement => (
    <NavButton 
        type="home"
        to={ROUTES.HOME}
        onClick={() => {
            //@ts-ignore
            OnHome();
        }}
    >
        <HomeIcon category={category} />
    </NavButton>
)