import { useEffect, useState } from "react"
import { SpriteProps } from "../components/atoms/Sprite"

// Parses page sprites dataset to <Sprite> props object
export function useSprites(dataset: SpriteDataset[]): SpriteProps[] {
    const [props, setProps] = useState<SpriteProps[]>([])
    useEffect(() => {
        setProps(
            dataset.map(
                ([id, z, width, x, y, i, duration, repeat, anim]) => ({
                    spriteId: id,
                    position: { x, y, i },
                    duration,
                    repeat,
                    width,
                    anim,
                    z,
                })
            )
        )
    }, [dataset])

    return props
}

export type SpriteDataset = [
    spriteId: SpriteProps["spriteId"],
    z: SpriteProps["z"],
    width: SpriteProps["width"],
    x: SpriteProps["position"]["x"],
    y: SpriteProps["position"]["y"],
    i: SpriteProps["position"]["i"],
    duration: SpriteProps["duration"],
    repeat: SpriteProps["repeat"],
    anim?: SpriteProps["anim"],
]
