import { ReactElement, Fragment } from "react"
import { Strong } from "../components/atoms/Strong"
import { DetailsTemplate } from "../components/templates/DetailsTemplate"
import { COMMON_LOCALES } from "../_config/common_locales"

export const GlassDetails = (): ReactElement => (
    <DetailsTemplate
        category="hazard"
        label={COMMON_LOCALES.labels.hazard.broken_glass}
        quote={{
            strings: [
                "En pleine route, un projectile fissure\nmon pare-brise ou mon phare avant."
            ],
            imageId: "car_with_broken_windshield",
            propWidth: "14%",
            smWidth: "25vw",
        }}
        datakeys={{
            source: "FFA 2019",
            simple: {
                imageId: "car_on_road",
                position: { x: 70, y: 50, centerTransform: true },
                width: "20%"
            },
            textIfSimple: (
                <Fragment>
                    Un des <Strong>sinistres les plus fréquents (1 sur 3)</Strong>
                </Fragment>
            )
        }}
        contract={
            <Fragment>
                Selon les formules, <Strong>le Bris de glace peut être une garantie optionnelle. </Strong>Etant un risque fréquent, il est important de vérifier si une franchise est appliquée que ce soit pour la réparation et/ou le remplacement.
            </Fragment>
        }
        adviceLink="#"
        advice={[
            <Fragment><Strong>Respectez les distances de sécurité</Strong> pour éviter toutes projections sur votre pare-brise venant du véhicule qui vous précède.</Fragment>,
            <Fragment><Strong>Réparez rapidement les impacts sur votre pare-brise </Strong>afin d’éviter que l’éclat ne se propage, devienne une fissure et diminue votre visibilité.</Fragment>,
        ]}
    />
)