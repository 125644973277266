import bike from "../images/anims/_sprites/bike.svg"
import car_repair from "../images/anims/_sprites/car_repair.svg"
import car_repair_man from "../images/anims/_sprites/car_repair_man.svg"
import car_with_broken_glass from "../images/anims/_sprites/car_with_broken_glass.svg"
import car_with_people from "../images/anims/_sprites/car_with_people.svg"
import collision_with_bike from "../images/anims/_sprites/collision_with_bike.svg"
import family from "../images/anims/_sprites/family.svg"
import injured_bike from "../images/anims/_sprites/injured_bike.svg"
import man_with_umbrella from "../images/anims/_sprites/man_with_umbrella.svg"
import thief from "../images/anims/_sprites/thief.svg"
import traffic_light from "../images/anims/_sprites/traffic_light.svg"
import tree from "../images/anims/_sprites/tree.svg"
import woman_phoning from "../images/anims/_sprites/woman_phoning.svg"
import woman_walking from "../images/anims/_sprites/woman_walking.svg"

import { SpriteId, SpriteData } from "../types"

export function getSpriteData(spriteId: SpriteId): SpriteData {
    const dataset: { [key in SpriteId]: SpriteData } = {
        bike: {
            src: bike,            
            alt: "bike".replace(/_/g, " "),
            aspectRatio: 1.029296875,
            spritesNb: 4,
        },
        car_repair: {
            src: car_repair,            
            alt: "car_repair".replace(/_/g, " "),
            aspectRatio: 2.734375,
            spritesNb: 3,
        },
        car_repair_man: {
            src: car_repair_man,            
            alt: "car_repair_man".replace(/_/g, " "),
            aspectRatio: 1,
            spritesNb: 3,
        },
        car_with_people: {
            src: car_with_people,            
            alt: "car_with_people".replace(/_/g, " "),
            aspectRatio: 1.49,
            spritesNb: 3,
        },
        car_with_broken_glass: {
            src: car_with_broken_glass,            
            alt: "car_with_broken_glass".replace(/_/g, " "),
            aspectRatio: 1.4688,
            spritesNb: 3,
        },
        collision_with_bike: {
            src: collision_with_bike,            
            alt: "collision_with_bike".replace(/_/g, " "),
            aspectRatio: 3.494,
            spritesNb: 3,
        },
        family: {
            src: family,            
            alt: "family".replace(/_/g, " "),
            aspectRatio: 1.513671875,
            spritesNb: 3,
        },
        injured_bike: {
            src: injured_bike,            
            alt: "injured_bike".replace(/_/g, " "),
            aspectRatio: 1.339407745,
            spritesNb: 3,
        },
        man_with_umbrella: {
            src: man_with_umbrella,            
            alt: "man_with_umbrella".replace(/_/g, " "),
            aspectRatio: 1,
            spritesNb: 3,
        },
        thief: {
            src: thief,            
            alt: "thief".replace(/_/g, " "),
            aspectRatio: 1,
            spritesNb: 3,
        },
        traffic_light: {
            src: traffic_light,            
            alt: "traffic_light".replace(/_/g, " "),
            aspectRatio: 1,
            spritesNb: 3,
        },
        tree: {
            src: tree,            
            alt: "tree".replace(/_/g, " "),
            aspectRatio: 1.0390625,
            spritesNb: 3,
        },
        woman_phoning: {
            src: woman_phoning,            
            alt: "woman_phoning".replace(/_/g, " "),
            aspectRatio: 1,
            spritesNb: 3,
        },
        woman_walking: {
            src: woman_walking,            
            alt: "woman_walking".replace(/_/g, " "),
            aspectRatio: 1,
            spritesNb: 3,
        },
    }
    return dataset[spriteId]
}