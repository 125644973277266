import styled, { css } from "styled-components"
import { theme } from "../../../styles"
import { WithCategory, ImageId } from "../../../types"
import { FC, ReactElement, Fragment, useEffect, useState, useRef } from "react"
import { Text } from "../../atoms/Text"
import { Image } from "../../atoms/Image"
import { getSvgById } from "../../../utils"
import { Headline } from "../../atoms/Headline"
import { _flexbox, _position_absolute, _PositionAbsolute, _font_size } from "../../../styles/mixins"
import { Link as UnstyledLink } from "react-router-dom"
import { makeQuestionMarkDance } from "../../../styles/anims"
import { breakpoint } from "../../../styles"
import { useMobileView } from "../../../hooks"

export interface DefinedBubbleProps extends WithCategory {
    withLink?: boolean
    position?: _PositionAbsolute | undefined
}

interface GenericBubbleProps extends DefinedBubbleProps {
    width?: string
    iconId: ImageId
    label?: string
    link: string
}

//! CONSTANTS
const widths = {
    withLink: {
        bubble: {
            screen: "13.54%",
            mobile: "38%",
        }, 
        svg: "65%",
    },
    withoutLink: {
        bubble: {
            screen: "16.6%",
            mobile: "42%", //! <= change
        }, 
        svg: "42%",
    },
}

/** Displays Bubble UI component with (or without) link to corresponding Scene page */
export const GenericBubble: FC<GenericBubbleProps> = ({
    category,
    label,
    iconId,
    link,
    withLink,
    position = undefined
}): ReactElement => {
    const isMobileView = useMobileView()
    const [view, setView] = useState<"screen"|"mobile">("screen")
    const [width, setWidth] = useState<typeof widths.withLink>(widths.withoutLink)
    const imgRef = useRef<HTMLElement>(null)
    const [widthPx, setWidthPx] = useState<number>(0)


    useEffect(
        () => {
            if(imgRef.current) setWidthPx(imgRef.current.clientWidth)
        }, []
    )

    useEffect(
        () => {
            setWidth(widths[withLink? "withLink":"withoutLink"])
            setView(isMobileView ? "mobile":"screen")
        }, [isMobileView, withLink]
    )

    const Content = (
        <Fragment>
            <Image imageId={iconId} width={width.svg} />
            <Headline sub="h2" color={theme.text.bubble}>{label}</Headline>
            <BubbleText bold color={theme.text.bubble}>?</BubbleText>
        </Fragment>
    )
    return (
        <BubbleShape
            ref={imgRef}
            category={category}
            width={width.bubble[view]}
            widthPx={widthPx}
            withLink={withLink}
            position={position}
        >
            {
                withLink ? (
                    <Link
                        to={link}
                        onClick={() => {
                            //@ts-ignore
                            OnHomePageCategory(category);
                        }}
                    >
                        {Content}
                    </Link>
                ) : Content
            }
        </BubbleShape>
    )
}

/** Styled components */

interface BubbleShapeProps extends Pick<GenericBubbleProps, "category" | "position" | "width" | "withLink"> {
    widthPx: number
}

/** Generic Bubble component, composed to get all listed categories */
const BubbleShape = styled.hgroup<BubbleShapeProps>`
    ${_flexbox({ fd: "column", jc: "center" })}
    width: ${props => props.width};
    ${props => css`height: calc(${props.widthPx}px / 1.094)`};
    aspect-ratio: 1.094;

    mask: url(${getSvgById("bubble").src});
    -webkit-mask: url(${getSvgById("bubble").src});
    mask-repeat: no-repeat;
    background-color: ${props => theme[props.category].bubble};
    gap: 7%;

    ${_font_size(50)}
    @media (max-width: ${breakpoint}px) {
        width: 42vw;
        height: 42vw;
        font-size: 7vw;
    }
    ${props => Boolean(props.position) && _position_absolute(props.position as _PositionAbsolute)};
    z-index: 200;
    &:hover {
        &:last-child {
            color: red;
        }
    }
`

const Link = styled(UnstyledLink)`
    width: inherit 80%;
    height: inherit 80%;
    text-decoration: none;
    ${_flexbox({ fd: "column", jc: "flex-end" })}
    gap: 5%;
    margin: auto;
`

const BubbleText = styled(Text)`
    transition: scale .8s ease-out;
    ${Link}:hover & {
        scale: 1.5;
        transition: scale 1s;
        ${makeQuestionMarkDance()};
    }
`