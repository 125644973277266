import styled, { css } from "styled-components"
import { Category, ImageId } from "../../types"
import { getSvgById } from "../../utils"

import { CSSProperties, FC, ReactElement } from "react"
import { _flexbox } from "../../styles/mixins"
import { Bubble } from "../molecules/Bubble"
import { _PositionAbsolute } from "../../styles/mixins"
import { breakpoint } from "../../styles"
import { useMobileView } from "../../hooks"

export interface MainProps {
    bgId: ImageId
    bgSm: ImageId
    smAr: number
    bubble?: {
        category: Category
        withLink?: boolean
        position: _PositionAbsolute
    }
    styles?: CSSProperties
    isDetails?: boolean
}

export const Main: FC<MainProps> = ({
    bgId,
    bgSm,
    smAr,
    bubble = undefined,
    isDetails = false,
    styles,
    children,
}): ReactElement => {
    const isMobileView = useMobileView()
    return isMobileView ? (
        <MobileMain bgSm={bgSm}>
            { children }
            <MobileBg bgSm={bgSm} smAr={smAr}/>
        </MobileMain>
    ) : (
        <ScreenMain bgId={bgId} isDetails={isDetails} style={styles}>
            {
                bubble && (
                    <Bubble
                        category={bubble.category}
                        withLink={Boolean(bubble.withLink)}
                        position={bubble.position}
                    />
                )
            }
            { children }
        </ScreenMain>
    )
}

/** Styled components */

const ScreenMain = styled.main<Omit<MainProps, "bgSm"|"smAr">>`
    position: relative;
    width: 100%;
    @media (min-width: ${breakpoint + 1}px) {
        min-width: 1200px;
        max-width: 1920px;
        min-height: 960px;
    }
    margin: auto;
    height: auto;
    /* aspect-ratio: 2; */
    box-sizing: border-box;
    background-image: url(${props => getSvgById(props.bgId).src});
    background-repeat: no-repeat;
    ${_flexbox({ fd: "column", jc: "flex-start" })}
    background-size: 102%;
    background-position: center;
    ${props => props.isDetails === true && css`
        aspect-ratio: unset;
        background-size: 1930px;
    `}   
`

const MobileMain = styled.main<Pick<MainProps, "bgSm">>`
    width: 100%;
    box-sizing: border-box;
    background-color: #f3f3f7;
`
const MobileBg = styled.section<Pick<MainProps, "bgSm"|"smAr">>`
    width: 100vw;
    aspect-ratio: ${props => props.smAr};
    ${props => css`height: calc(100vw / ${props.smAr})`};
    box-sizing: border-box;
    background-image: url(${props => getSvgById(props.bgSm).src});
    
    background-size: cover;
    background-position: center;
`