import { ReactElement, Fragment } from "react"
import { Strong } from "../components/atoms/Strong"
import { Text } from "../components/atoms/Text"
import { DetailsTemplate } from "../components/templates/DetailsTemplate"
import { COMMON_LOCALES } from "../_config/common_locales"

export const PunctureDetails = (): ReactElement => (
    <DetailsTemplate
        category="breakdown"
        label={COMMON_LOCALES.labels.breakdown.puncture}
        quote={{
            strings: [
                "Ma voiture tombe en panne en pleine route."
            ],
            imageId: "car_repair",
            propWidth: "35%",
            smWidth: "45vw",
        }}
        datakeys={{
            source: "Pacifica",
            simple: {
                imageId: "assistance",
                position: { x: 75, y: 50, centerTransform: true },
                width: "8%"
            },
            textIfSimple: (
                <Fragment>
                    En 2020, un <Strong>appel toutes les 2 mn</Strong> à notre assistance.
                </Fragment>
            )
        }}
        unwrapContract
        contract={
            <Fragment>
                <Text inContractBox style={{ paddingBottom: 5 }}><Strong>Certaines formules prévoient une franchise kilométrique</Strong> pour intervenir en cas de panne (25 km, 50 km ou +). Si vous souhaitez être dépanné où que vous soyez même devant chez vous, vérifiez que votre contrat le prévoie.</Text>
                <Text inContractBox><Strong>Bénéficiez-vous d’un véhicule de prêt </Strong>pour continuer vos déplacements habituels le temps des réparations ?</Text>
            </Fragment>
        }
        advice={[
            <Fragment><Strong>Contrôlez régulièrement les feux, les pneumatiques et les niveaux</Strong> (huile moteur, liquides de freins, de refroidissement, lave-glace, carburants...).</Fragment>,
            <Fragment><Strong>Vérifiez d'avoir à disposition le triangle de pré-signalisation et les gilets fluorescents.</Strong> Être visible de loin, en cas d'arrêt d'urgence, est essentiel pour votre sécurité.</Fragment>,
        ]}
    />
)