import styled from "styled-components"
import { _flexbox } from "../../styles/mixins"
import { Image } from "../atoms/Image"
import { Link } from "react-router-dom"
import { ROUTES } from "../../_config/routes"
import { breakpoint } from "../../styles"
import { useMobileView } from "../../hooks"

const links = [
    {
        label: "Mentions légales",
        link: ROUTES.LEGAL,
        onClick: () => {
            //@ts-ignore
            OnMentionsLegales();
            return false
        }
    },{
        label: "Politique des cookies",
        link: ROUTES.COOKIES.POLICY,
        onClick: () => {
            //@ts-ignore
            OnPolitiqueCookies();
            return false
        }
    },{
        label: "Gestion des coookies",
        link: ROUTES.COOKIES.MANAGEMENT,
        onClick: () => {
            //@ts-ignore
            tC.privacyCenter.showPrivacyCenter();
            //@ts-ignore
            OnGestionCookies();
            return false
        }
    },
]


export const Footer = () => {
    const isMobileView = useMobileView()
    return (
        <Container>
            <ul>
                {
                    links.map(
                        ({label, link, onClick}) => <Link key={label} to={link} onClick={onClick}>{label}</Link>
                    )
                }
            </ul>
            <Image 
                imageId="ca_logo" 
                height={isMobileView ? "auto": "100px"}
                width={isMobileView ? "20vw":"auto"}
            />
        </Container>
    )
}

const Container = styled.footer`
    color: #000;
    background-color: #fff;
    padding: 15px 30px;
    width: 100%;
    min-width: 1200px;
    max-width: 1920px;
    margin: auto;
    ${_flexbox({jc: "space-between"})}
    & > :first-child {
        ${_flexbox({jc: "space-between"})}
        > * {
            text-decoration: none;
            padding: 0 15px;
            color: #000;
            :first-child {
                padding-left: 0;
            }
            :not(:first-child) {
                position: relative;
                ::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    height: 100%;
                    border-left: 1px solid #000;
                }
            }
        }
    }

    @media (max-width: ${breakpoint}px) {
        width: 100vw;
        min-width: 100vw;
        max-width: 100vw;
        font-size: 1.7vw;
        padding: 2vw;
        & > :first-child > * {
            padding: 0 2vw;
        }
    }
`
