import { ReactElement, Fragment } from "react"
import { Strong } from "../components/atoms/Strong"
import { DetailsTemplate } from "../components/templates/DetailsTemplate"
import { COMMON_LOCALES } from "../_config/common_locales"

export const CarDetails = (): ReactElement => (
    <DetailsTemplate
        category="crash"
        label={COMMON_LOCALES.labels.crash.car}
        quote={{
            strings: ["Vous freinez trop tardivement\net percutez le véhicule devant vous."],
            imageId: "collision_between_cars",
            propWidth: "38%",
            smWidth: "50vw",
        }}
        datakeys={{
            source: "L'observatoire national interministériel de la sécurité routière - 2019",
            textIfList: "À chaque âge,\nun facteur de risque d'accident",
            list: [
                {
                    digits: "18-24 ans",
                    subject: "la vitesse",
                    imageId: "speedometer",
                },
                {
                    digits: "24-44 ans ",
                    subject: "l’alcool",
                    imageId: "alcohol",
                },
                {
                    digits: "75 ans et +",
                    subject: "l'inattention",
                    imageId: "man_with_stick",
                }
            ]
        }}
        contract={
            <Fragment>
                Pour être indemnisé en toutes circonstances que vous soyez responsable ou pas, <Strong>c’est la formule Tous risques qui vous permet d’être pris en charge</Strong> pour tous les frais liés à la remise en état de votre véhicule. <Strong>Vérifiez si vous bénéficiez d’un véhicule de prêt le temps des travaux de réparation.</Strong>
            </Fragment>
        }
        adviceLink="#"
        advice={[
            <Fragment>
                <Strong>J’anticipe la perte d’adhérence en cas de verglas, </Strong>neige ou pluie en évitant toutes manœuvres brusques.
            </Fragment>
        ]}
    />
)