import { FC, ReactElement } from "react"
import styled from "styled-components"
import { Text } from "../atoms/Text"
import { _flexbox, _font_size } from "../../styles/mixins"
import { DatakeyItem, DatakeyItemProps } from "../molecules/DatakeyItem"
import { theme, breakpoint } from "../../styles"
import { WithCategory } from "../../types"

interface DatakeysListProps extends WithCategory {
    list: Omit<DatakeyItemProps, "color">[]
    text?: string
}

/** UI component which stores datakeys items in Details view when content is a list */
export const DatakeysList: FC<DatakeysListProps> = ({
    list,
    category,
    text = undefined
}): ReactElement => {
    const color = theme[category].balloon
    const items = (
        <List>
            {
                list.map(
                    props => <DatakeyItem key={props.imageId} {...props} color={color} />
                )
            }
        </List>
    )
    return text ? (
        <WithTextContainer color={color}>
            <Text>{text}</Text>
            {items}
        </WithTextContainer>
    ) : items
}

/** Styled components */

const List = styled.ul`
    ${_flexbox()};
    flex: 2;

    @media (max-width: ${breakpoint}px) {
        ${_flexbox({ fd: "column" })};
    }
`
const WithTextContainer = styled.section<Pick<DatakeyItemProps, "color">>`
    ${_flexbox()};
    @media (max-width: ${breakpoint}px) {
        ${_flexbox({ fd: "column" })};
        gap: 3vw;
    }
    
    & > :first-child {
        flex: 1;
        box-sizing: content-box;
        ${_font_size(24)};
        font-family: Gotham_Rounded;
        padding: 0 0 0 2.8%;
        color: ${props => props.color};
        @media (max-width: ${breakpoint}px) {
            padding: 0 5vw;
            text-align: center;
            white-space: unset;
            font-size: 5vw;
        }
    }
`