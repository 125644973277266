import { ReactElement } from "react"
import styled from "styled-components"
import { Headline } from "../components/atoms/Headline"
import { Bubble } from "../components/molecules/Bubble"
import { SceneTemplate } from "../components/templates/SceneTemplate"
import { ImageDataset, SpriteDataset } from "../hooks"
import { moveCloud, moveHomeCar, moveSprite } from "../styles/anims"
import { _flexbox } from "../styles/mixins"
import { breakpoint } from "../styles"
import { Category } from "../types"

/** Displays Homepage */
export const Home = (): ReactElement => {
    const categories: Category[] = ["crash", "hazard", "breakdown"]

    const sprites: SpriteDataset[] = [
        ["car_with_people", 11, "14.0625%", 55, 71, true, "1s", "infinite", moveHomeCar("4s", 1)],
        ["bike", 11, "10.41%", 35.5, 66.4, true, ".5s", 6, moveSprite("-400px", "0px", 3, 1)],
        ["family", 10, "14.0625%", 15, 67, true, ".8s", 6, moveSprite("-300px", "0px", 4.8, 1)],
        ["traffic_light", 10, "14%", 54, 51.5, true, "4s", 1 ]
    ]

    const images: ImageDataset[] = [
        ["sun", 8, "14%", 69, 42, true, ],
        ["buildings", 10, "20%", 71, 61, true, ],
        ["traffic_light", 10, "2.6%", 54, 51.5, true, ],
        ["cloud", 10, "18.75%", 7, 42, true, moveCloud()],
        ["cloud_reversed", 9, "18.75%", 62, 49.5, true, moveCloud()]
    ]
    
    return (
        <SceneTemplate
            category={undefined}
            bgId="bg_home"
            bgSm="bg_sm_home"
            smAr={1.629120084}
            imagesDataset={images}
            spritesDataset={sprites}
            framesDataset={[]}
        >
            <Headline>{"À quels risques automobiles\n pouvez-vous être confrontés ?"}</Headline>
            <Nav>
                {
                    categories.map(
                        cat => (
                            <Bubble key={cat} category={cat} withLink />
                        )
                    )
                }
            </Nav>
        </SceneTemplate>
    )
}

const Nav = styled.nav`
    width: 100%;
    gap: 2.8%;
    ${_flexbox({jc: "center"})};
    @media (max-width: ${breakpoint}px) {
        ${_flexbox({jc: "center", fd: "column"})};
        gap: 2.8vw;
    }
`