import { Link } from "react-router-dom"
import styled from "styled-components"
import { breakpoint, theme } from "../../styles"
import { _flexbox, _button_shadow } from "../../styles/mixins"
interface NavButtonProps {
    to: string
    type: "home" | "backspace"
    onClick?: () => void
}

/** Generic navigation button */
export const NavButton = styled(Link) <NavButtonProps>`
    background-color: ${props => theme.navButton[props.type]};
    height: 50px;
    @media (max-width: ${breakpoint}px) {
        width: 10vw;
        height: 10vw;
        order: -1;
    }
    aspect-ratio: 1;
    width: 50px;
    border-radius: 100%;
    border: 1px solid ${theme.navButton.home};
    ${_flexbox()};
    ${_button_shadow()};
`