import { css, CSSProperties } from "styled-components"

interface _Flexbox {
    fd?: CSSProperties["flexDirection"]
    jc?: CSSProperties["justifyContent"]
    ai?: CSSProperties["alignItems"]
}

const defaultArgs: _Flexbox = {
    fd: "row",
    jc: "center",
    ai: "center"
}

/** Applies flexbox styles to the element */
export const _flexbox = ({
    fd = defaultArgs.fd,
    jc = defaultArgs.jc,
    ai = defaultArgs.ai,
}: _Flexbox = defaultArgs) => css`
    display: flex;
    flex-direction: ${fd};
    justify-content: ${jc};
    align-items: ${ai};
`