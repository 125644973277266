import arrow_backspace from "../images/arrow_backspace.svg"
import arrow_down from "../images/arrow_down.svg"
import arrow_right from "../images/arrow_right.svg"
import bg_breakdown from "../images/bg/bg_breakdown.svg"
import bg_crash from "../images/bg/bg_crash.svg"
import bg_hazard from "../images/bg/bg_hazard.svg"
import bg_home from "../images/bg/bg_home.svg"
import bg_root from "../images/bg/bg_root.svg"
import bg_sm_breakdown from "../images/bg/bg_sm_breakdown.svg"
import bg_sm_crash from "../images/bg/bg_sm_crash.svg"
import bg_sm_hazard from "../images/bg/bg_sm_hazard.svg"
import bg_sm_home from "../images/bg/bg_sm_home.svg"
import assistance from "../images/icons/breakdown/assistance.svg"
import broken_car from "../images/icons/breakdown/broken_car.svg"
import car_repair from "../images/icons/breakdown/car_repair.svg"
import alcohol from "../images/icons/crash/alcohol.svg"
import carcrash from "../images/icons/crash/carcrash.svg"
import carcrash_with_injury_man from "../images/icons/crash/carcrash_with_injury_man.svg"
import collision from "../images/icons/crash/collision.svg"
import collision_between_cars from "../images/icons/crash/collision_between_cars.svg"
import man_on_crosswalk from "../images/icons/crash/man_on_crosswalk.svg"
import man_walking from "../images/icons/crash/man_walking.svg"
import man_with_stick from "../images/icons/crash/man_with_stick.svg"
import noisy_car from "../images/icons/crash/noisy_car.svg"
import speedometer from "../images/icons/crash/speedometer.svg"
import ca_logo from "../images/icons/global/ca_logo.svg"
import check from "../images/icons/global/check.svg"
import house from "../images/icons/global/house.svg"
import message from "../images/icons/global/message.svg"
import quote_end from "../images/icons/global/quote_end.svg"
import quote_start from "../images/icons/global/quote_start.svg"
import car_in_rain from "../images/icons/hazard/car_in_rain.svg"
import car_on_road from "../images/icons/hazard/car_on_road.svg"
import car_under_water from "../images/icons/hazard/car_under_water.svg"
import car_with_broken_windshield from "../images/icons/hazard/car_with_broken_windshield.svg"
import carjack from "../images/icons/hazard/carjack.svg"
import carjack_with_chrono from "../images/icons/hazard/carjack_with_chrono.svg"
import thief_carjacking from "../images/icons/hazard/thief_carjacking.svg"
import repairman from "../images/props/breakdown/repairman.svg"
import tow_truck from "../images/props/breakdown/tow_truck.svg"
import woman_phoning from "../images/props/breakdown/woman_phoning.svg"
import man_with_broken_bike from "../images/props/crash/man_with_broken_bike.svg"
import pileup from "../images/props/crash/pileup.svg"
import signal_cone from "../images/props/crash/signal_cone.svg"
import woman_walking from "../images/props/crash/woman_walking.svg"
import balloon from "../images/props/global/balloon.svg"
import bubble from "../images/props/global/bubble.svg"
import buildings from "../images/props/global/buildings.svg"
import cloud from "../images/props/global/cloud.svg"
import cloud_reversed from "../images/props/global/cloud_reversed.svg"
import sun from "../images/props/global/sun.svg"
import big_cloud from "../images/props/hazard/big_cloud.svg"
import big_cloud_2 from "../images/props/hazard/big_cloud_2.svg"
import blue_cloud from "../images/props/hazard/blue_cloud.svg"
import broken_tree from "../images/props/hazard/broken_tree.svg"
import burning_car from "../images/props/hazard/burning_car.svg"
import man_with_umbrella from "../images/props/hazard/man_with_umbrella.svg"
import big_white_cloud from "../images/props/hazard/big_white_cloud.svg"
import rain from "../images/props/hazard/rain.svg"
import thief from "../images/props/hazard/thief.svg"
import thunder from "../images/props/hazard/thunder.svg"
import bike_man from "../images/props/home/bike_man.svg"
import car_with_people from "../images/props/home/car_with_people.svg"
import traffic_light from "../images/props/home/traffic_light.svg"
import walking_family from "../images/props/home/walking_family.svg"

import { ImageData, ImageId } from "../types"

export function getSvgById(imageId: ImageId): ImageData {
    const srcs: { [key in ImageId]: ImageData } = {
        arrow_backspace: {
            src: arrow_backspace,
            alt: "arrow_backspace".replace(/_/g, " ")
        },
        arrow_down: {
            src: arrow_down,
            alt: "arrow_down".replace(/_/g, " ")
        },
        arrow_right: {
            src: arrow_right,
            alt: "arrow_right".replace(/_/g, " ")
        },
        bg_breakdown: {
            src: bg_breakdown,
            alt: "bg_breakdown".replace(/_/g, " ")
        },
        bg_crash: {
            src: bg_crash,
            alt: "bg_crash".replace(/_/g, " ")
        },
        bg_hazard: {
            src: bg_hazard,
            alt: "bg_hazard".replace(/_/g, " ")
        },
        bg_home: {
            src: bg_home,
            alt: "bg_home".replace(/_/g, " ")
        },
        bg_root: {
            src: bg_root,
            alt: "bg_root".replace(/_/g, " ")
        },
        bg_sm_breakdown: {
            src: bg_sm_breakdown,
            alt: "bg_sm_breakdown".replace(/_/g, " ")
        },
        bg_sm_crash: {
            src: bg_sm_crash,
            alt: "bg_sm_crash".replace(/_/g, " ")
        },
        bg_sm_hazard: {
            src: bg_sm_hazard,
            alt: "bg_sm_hazard".replace(/_/g, " ")
        },
        bg_sm_home: {
            src: bg_sm_home,
            alt: "bg_sm_home".replace(/_/g, " ")
        },
        assistance: {
            src: assistance,
            alt: "assistance".replace(/_/g, " ")
        },
        broken_car: {
            src: broken_car,
            alt: "broken_car".replace(/_/g, " ")
        },
        car_repair: {
            src: car_repair,
            alt: "car_repair".replace(/_/g, " ")
        },
        alcohol: {
            src: alcohol,
            alt: "alcohol".replace(/_/g, " ")
        },
        carcrash: {
            src: carcrash,
            alt: "carcrash".replace(/_/g, " ")
        },
        carcrash_with_injury_man: {
            src: carcrash_with_injury_man,
            alt: "carcrash_with_injury_man".replace(/_/g, " ")
        },
        collision: {
            src: collision,
            alt: "collision".replace(/_/g, " ")
        },
        collision_between_cars: {
            src: collision_between_cars,
            alt: "collision_between_cars".replace(/_/g, " ")
        },
        man_on_crosswalk: {
            src: man_on_crosswalk,
            alt: "man_on_crosswalk".replace(/_/g, " ")
        },
        man_walking: {
            src: man_walking,
            alt: "man_walking".replace(/_/g, " ")
        },
        man_with_stick: {
            src: man_with_stick,
            alt: "man_with_stick".replace(/_/g, " ")
        },
        noisy_car: {
            src: noisy_car,
            alt: "noisy_car".replace(/_/g, " ")
        },
        speedometer: {
            src: speedometer,
            alt: "speedometer".replace(/_/g, " ")
        },
        ca_logo: {
            src: ca_logo,
            alt: "ca_logo".replace(/_/g, " ")
        },
        check: {
            src: check,
            alt: "check".replace(/_/g, " ")
        },
        house: {
            src: house,
            alt: "house".replace(/_/g, " ")
        },
        message: {
            src: message,
            alt: "message".replace(/_/g, " ")
        },
        quote_end: {
            src: quote_end,
            alt: "quote_end".replace(/_/g, " ")
        },
        quote_start: {
            src: quote_start,
            alt: "quote_start".replace(/_/g, " ")
        },
        car_in_rain: {
            src: car_in_rain,
            alt: "car_in_rain".replace(/_/g, " ")
        },
        car_on_road: {
            src: car_on_road,
            alt: "car_on_road".replace(/_/g, " ")
        },
        car_under_water: {
            src: car_under_water,
            alt: "car_under_water".replace(/_/g, " ")
        },
        car_with_broken_windshield: {
            src: car_with_broken_windshield,
            alt: "car_with_broken_windshield".replace(/_/g, " ")
        },
        carjack: {
            src: carjack,
            alt: "carjack".replace(/_/g, " ")
        },
        carjack_with_chrono: {
            src: carjack_with_chrono,
            alt: "carjack_with_chrono".replace(/_/g, " ")
        },
        thief_carjacking: {
            src: thief_carjacking,
            alt: "thief_carjacking".replace(/_/g, " ")
        },
        repairman: {
            src: repairman,
            alt: "repairman".replace(/_/g, " ")
        },
        tow_truck: {
            src: tow_truck,
            alt: "tow_truck".replace(/_/g, " ")
        },
        woman_phoning: {
            src: woman_phoning,
            alt: "woman_phoning".replace(/_/g, " ")
        },
        man_with_broken_bike: {
            src: man_with_broken_bike,
            alt: "man_with_broken_bike".replace(/_/g, " ")
        },
        pileup: {
            src: pileup,
            alt: "pileup".replace(/_/g, " ")
        },
        signal_cone: {
            src: signal_cone,
            alt: "signal_cone".replace(/_/g, " ")
        },
        woman_walking: {
            src: woman_walking,
            alt: "woman_walking".replace(/_/g, " ")
        },
        balloon: {
            src: balloon,
            alt: "balloon".replace(/_/g, " ")
        },
        bubble: {
            src: bubble,
            alt: "bubble".replace(/_/g, " ")
        },
        buildings: {
            src: buildings,
            alt: "buildings".replace(/_/g, " ")
        },
        cloud: {
            src: cloud,
            alt: "cloud".replace(/_/g, " ")
        },
        cloud_reversed: {
            src: cloud_reversed,
            alt: "cloud_reversed".replace(/_/g, " ")
        },
        sun: {
            src: sun,
            alt: "sun".replace(/_/g, " ")
        },
        big_cloud: {
            src: big_cloud,
            alt: "big_cloud".replace(/_/g, " ")
        },
        blue_cloud: {
            src: blue_cloud,
            alt: "blue_cloud".replace(/_/g, " ")
        },
        big_cloud_2: {
            src: big_cloud_2,
            alt: "big_cloud_2".replace(/_/g, " ")
        },
        broken_tree: {
            src: broken_tree,
            alt: "broken_tree".replace(/_/g, " ")
        },
        burning_car: {
            src: burning_car,
            alt: "burning_car".replace(/_/g, " ")
        },
        man_with_umbrella: {
            src: man_with_umbrella,
            alt: "man_with_umbrella".replace(/_/g, " ")
        },
        rain: {
            src: rain,
            alt: "rain".replace(/_/g, " ")
        },
        big_white_cloud: {
            src: big_white_cloud,
            alt: "big_white_cloud".replace(/_/g, " ")
        },
        thief: {
            src: thief,
            alt: "thief".replace(/_/g, " ")
        },
        thunder: {
            src: thunder,
            alt: "thunder".replace(/_/g, " ")
        },
        bike_man: {
            src: bike_man,
            alt: "bike_man".replace(/_/g, " ")
        },
        car_with_people: {
            src: car_with_people,
            alt: "car_with_people".replace(/_/g, " ")
        },
        traffic_light: {
            src: traffic_light,
            alt: "traffic_light".replace(/_/g, " ")
        },
        walking_family: {
            src: walking_family,
            alt: "walking_family".replace(/_/g, " ")
        },
    }
    return srcs[imageId]
}