import { css, CSSProperties, keyframes } from "styled-components"

const movement = (from: string, to: string) => keyframes`
    from { transform: translate(${from}); }
    to { transform: translate(${to}); }
`

export const moveCloud = (
    from: string = "0px", 
    to: string = "300px", 
    duration: CSSProperties["animationDuration"] = "20s",
    direction: CSSProperties["animationDirection"] = "alternate",
    repeat: CSSProperties["animationIterationCount"] = "infinite",
    timing: CSSProperties["animationTimingFunction"] = "ease-in-out",
) => css`
    animation-name: ${movement(from, to)};
    animation-direction: ${direction};
    animation-iteration-count: ${repeat};
    animation-timing-function: ${timing};
    animation-duration: ${duration};
`