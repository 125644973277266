import { ReactElement } from "react"
import { BalloonProps } from "../components/molecules/Balloon"
import { SceneTemplate } from "../components/templates/SceneTemplate"
import { COMMON_LOCALES } from "../_config/common_locales"
import { ROUTES } from "../_config/routes"
import {
    ImageDataset,
    SpriteDataset,
    FrameDataset
} from "../hooks"
import { 
    dispelSmoke,
    moveSprite,
} from "../styles/anims"

export const CrashScene = (): ReactElement => { 
    const balloons: BalloonProps[] = [
        {
            category: "crash",
            label: COMMON_LOCALES.labels.crash.others,
            link: ROUTES.CRASH.OTHERS,
            size: "md",
            x: 35,
            y: 68,
        }, {
            category: "crash",
            label: COMMON_LOCALES.labels.crash.car,
            link: ROUTES.CRASH.CAR,
            size: "xl",
            x: 50,
            y: 59,
        }, {
            category: "crash",
            label: COMMON_LOCALES.labels.crash.driver,
            link: ROUTES.CRASH.DRIVER,
            size: "xs",
            x: 65,
            y: 46,
        },
    ]

    const images: ImageDataset[] = [
        ["signal_cone", 12, "3.9%", 45, 79, false],
    ]
    const sprites: SpriteDataset[] = [
        ["collision_with_bike", 11, "51%", 23.5, 46, true, "800ms", 3],
        ["woman_walking", 12, "14%", 69.5, 57, true, "4s", "infinite", moveSprite("250px, 50px", "0px", 3, 1, "ease-out")],
        ["injured_bike", 12, "18%", 21, 72, true, "600ms", 4],
    ]

    const frames: FrameDataset[] = [
        [9, 3000, false, [
            ["white_smoke", "5%", { x: 48, y: 65 }, dispelSmoke("0s")],
            ["white_smoke", "0%", { x: 48, y: 65 }, dispelSmoke("4s")],
        ]],
    ]
    
    return (
        <SceneTemplate
            category="crash"
            bgId="bg_crash"
            bgSm="bg_sm_crash"
            smAr={1.601639198}
            balloons={balloons}
            imagesDataset={images}
            spritesDataset={sprites}
            framesDataset={frames}
        />
        
    )
}