import { ReactElement, Fragment } from "react"
import { Strong } from "../components/atoms/Strong"
import { DetailsTemplate } from "../components/templates/DetailsTemplate"
import { COMMON_LOCALES } from "../_config/common_locales"

export const DriverDetails = (): ReactElement => (
    <DetailsTemplate
        category="crash"
        label={COMMON_LOCALES.labels.crash.driver}
        quote={{
            strings: ["Je suis responsable de l'accident (ou sans tiers identifié)\net je suis blessé."],
            imageId: "carcrash",
            propWidth: "30%",
            smWidth: "50vw",
        }}
        datakeys={{
            source: "ONISR Observatoire National Interministériel de la Sécurité Routière",
            simple: {
                imageId: "noisy_car",
                position: { x: 75, y: 45, centerTransform: true },

            },
            textIfSimple: (
                <Fragment>
                    <Strong bigger>74165</Strong> personnes ont été blessées sur la route en 2019.
                </Fragment>
            )
        }}
        contract={
            <Fragment>
                <Strong>La loi ne prévoit pas d’indemnisation si vous êtes blessé lors d’un accident dont vous êtes responsable</Strong> ou sans tiers identifié. Il est donc important d’être couvert pour toutes les conséquences que vous pourriez subir et qui peuvent impacter le quotidien (assistance, perte de revenus, aménagement du véhicule ou de l’habitat) car cette garantie peut être optionnelle et/ou le niveau de couverture différent.
            </Fragment>
        }
        adviceLink="#"
        advice={[
            <Fragment><Strong>Restez concentré sur votre conduite</Strong> et ne vous laissez pas distraire par votre téléphone portable ou autres distracteurs.</Fragment>,
            <Fragment><Strong>En cas de long trajet, prévoyez une pause toutes les deux heures,</Strong> bougez, hydratez-vous et aérez votre voiture.</Fragment>,
        ]}
    />
)