import { css, CSSProperties, keyframes } from "styled-components"

const scale = keyframes`
    0%, 100% { transform: translate(0px, 0px) scale(1); }
         50% { transform: translate(0px, -10px) scale(1.5); }
`

const color = keyframes`
    0%, 100% { filter: saturate(1) brightness(1); }
         50% { filter: saturate(5) brightness(2); }
`

const smoke = keyframes`
0% {
    opacity: 0;
    filter: saturate(1);
    transform: translate(0px, 0px) scale(1);
}
50% { 
    opacity: .8;
    filter: saturate(0);
    transform: translate(0px, -10px) scale(1.5); 
}
100% {
    opacity: 0;
    filter: saturate(0);
    transform: translate(0px, -20px) scale(2); 
}
`


export const glitteringFire = (
    duration: CSSProperties["animationDuration"] = "2s",
) => css`
    animation: ${scale} ${duration} linear infinite, ${color} ${duration} ease-in-out infinite;
    -webkit-animation: ${scale} ${duration} linear infinite, ${color} ${duration} ease-in-out infinite;
`

export const fireSmoke = (
    duration: CSSProperties["animationDuration"] = "2s",
) => css`
    animation: ${smoke} ${duration} linear infinite;
    -webkit-animation: ${smoke} ${duration} linear infinite;
`