import { BrowserRouter, Switch, Route } from "react-router-dom"
import { ROUTES } from "../_config/routes"
// import { subdomain } from "../_config/subdomain"

import { Home } from "../pages/Home"
import { HazardScene } from "../pages/HazardScene"
import { BreakdownScene } from "../pages/BreakdownScene"
import { CrashScene } from "../pages/CrashScene"
import { DriverDetails } from "../pages/DriverDetails"
import { OthersDetails } from "../pages/OthersDetails"
import { CarDetails } from "../pages/CarDetails"
import { VandalismDetails } from "../pages/VandalismDetails"
import { GlassDetails } from "../pages/GlassDetails"
import { WeatherDetails } from "../pages/WeatherDetails"
import { PunctureDetails } from "../pages/PunctureDetails"

export const Router = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path={ROUTES.HOME} component={Home} />
            <Route exact path={ROUTES.HAZARD.SCENE} component={HazardScene} />
            <Route exact path={ROUTES.BREAKDOWN.SCENE} component={BreakdownScene} />
            <Route exact path={ROUTES.CRASH.SCENE} component={CrashScene} />
            <Route exact path={ROUTES.CRASH.DRIVER} component={DriverDetails} />
            <Route exact path={ROUTES.CRASH.OTHERS} component={OthersDetails} />
            <Route exact path={ROUTES.CRASH.CAR} component={CarDetails} />
            <Route exact path={ROUTES.HAZARD.VANDALISM} component={VandalismDetails} />
            <Route exact path={ROUTES.HAZARD.BROKEN_GLASS} component={GlassDetails} />
            <Route exact path={ROUTES.HAZARD.WEATHER} component={WeatherDetails} />
            <Route exact path={ROUTES.BREAKDOWN.PUNCTURE} component={PunctureDetails} />
        </Switch>
    </BrowserRouter>
)