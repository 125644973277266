import { ReactElement, Fragment } from "react"
import { Strong } from "../components/atoms/Strong"
import { DetailsTemplate } from "../components/templates/DetailsTemplate"
import { COMMON_LOCALES } from "../_config/common_locales"

export const WeatherDetails = (): ReactElement => (
    <DetailsTemplate
        category="hazard"
        label={COMMON_LOCALES.labels.hazard.weather}
        quote={{
            strings: [
                "Un violent orage accompagné de grêle\nendommage la carrosserie de mon véhicule.",
                "Une tempête éclate et les arbres de la rue \ntombent sur mon véhicule."
            ],
            imageId: "car_in_rain",
            propWidth: "17%"
        }}
        datakeys={{
            source: "ACPR",
            simple: {
                imageId: "car_under_water",
                position: { x: 80, y: 50, centerTransform: true },
                width: "15%",
            },
            textIfSimple: (
                <Fragment>
                    En France, d’ici 2050, le <Strong>nombre de catastrophes naturelles augmentera de 2 à 5 fois</Strong> selon les régions.
                </Fragment>
            )
        }}
        contract={
            <Fragment>
                C’est une <Strong>garantie qui peut être optionnelle</Strong> selon les formules.
            </Fragment>
        }
        adviceLink="#"
        advice={[
            <Fragment><Strong>Suivez les informations sur l’évolution de la situation météo </Strong>attendue dans les prochaines heures.</Fragment>,
            <Fragment><Strong>Prévoyez la mise en sécurité de vos véhicules exposés.</Strong><br />Evitez tout déplacement (à pied, en voiture).</Fragment>,
        ]}
    />
)