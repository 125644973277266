import bike_1 from "../images/anims/bike_1.svg"
import bike_2 from "../images/anims/bike_2.svg"
import bike_3 from "../images/anims/bike_3.svg"
import bike_4 from "../images/anims/bike_4.svg"
import car_repair_1 from "../images/anims/car_repair_1.svg"
import car_repair_2 from "../images/anims/car_repair_2.svg"
import car_repair_3 from "../images/anims/car_repair_3.svg"
import car_repair_man_1 from "../images/anims/car_repair_man_1.svg"
import car_repair_man_2 from "../images/anims/car_repair_man_2.svg"
import car_repair_man_3 from "../images/anims/car_repair_man_3.svg"
import car_repair_smoke from "../images/anims/car_repair_smoke.svg"
import car_with_broken_glass_1 from "../images/anims/car_with_broken_glass_1.svg"
import car_with_broken_glass_2 from "../images/anims/car_with_broken_glass_2.svg"
import car_with_broken_glass_3 from "../images/anims/car_with_broken_glass_3.svg"
import car_with_people_1 from "../images/anims/car_with_people_1.svg"
import car_with_people_2 from "../images/anims/car_with_people_2.svg"
import car_with_people_3 from "../images/anims/car_with_people_3.svg"
import collision_with_bike_1 from "../images/anims/collision_with_bike_1.svg"
import collision_with_bike_2 from "../images/anims/collision_with_bike_2.svg"
import collision_with_bike_3 from "../images/anims/collision_with_bike_3.svg"
import family_1 from "../images/anims/family_1.svg"
import family_2 from "../images/anims/family_2.svg"
import family_3 from "../images/anims/family_3.svg"
import fire from "../images/anims/fire.svg"
import man_with_umbrella_1 from "../images/anims/man_with_umbrella_1.svg"
import man_with_umbrella_2 from "../images/anims/man_with_umbrella_2.svg"
import man_with_umbrella_3 from "../images/anims/man_with_umbrella_3.svg"
import smoke from "../images/anims/smoke.svg"
import thief_1 from "../images/anims/thief_1.svg"
import thief_2 from "../images/anims/thief_2.svg"
import thief_3 from "../images/anims/thief_3.svg"
import traffic_light_green from "../images/anims/traffic_light_green.svg"
import traffic_light_orange from "../images/anims/traffic_light_orange.svg"
import traffic_light_red from "../images/anims/traffic_light_red.svg"
import tree_1 from "../images/anims/tree_1.svg"
import tree_2 from "../images/anims/tree_2.svg"
import tree_3 from "../images/anims/tree_3.svg"
import white_smoke from "../images/anims/white_smoke.svg"
import woman_phoning_1 from "../images/anims/woman_phoning_1.svg"
import woman_phoning_2 from "../images/anims/woman_phoning_2.svg"
import woman_phoning_3 from "../images/anims/woman_phoning_3.svg"
import woman_walking_1 from "../images/anims/woman_walking_1.svg"
import woman_walking_2 from "../images/anims/woman_walking_2.svg"
import woman_walking_3 from "../images/anims/woman_walking_3.svg"

import { FrameData, FrameId } from "../types"

export function getFrame(id: FrameId): FrameData {
    const srcs = {
        bike_1: {
            src: bike_1,
            alt: "bike 1"
        },
        bike_2: {
            src: bike_2,
            alt: "bike 2"
        },
        bike_3: {
            src: bike_3,
            alt: "bike 3"
        },
        bike_4: {
            src: bike_4,
            alt: "bike 4"
        },
        car_repair_1: {
            src: car_repair_1,
            alt: "car repair 1"
        },
        car_repair_2: {
            src: car_repair_2,
            alt: "car repair 2"
        },
        car_repair_3: {
            src: car_repair_3,
            alt: "car repair 3"
        },
        car_repair_man_1: {
            src: car_repair_man_1,
            alt: "car repair man 1"
        },
        car_repair_man_2: {
            src: car_repair_man_2,
            alt: "car repair man 2"
        },
        car_repair_man_3: {
            src: car_repair_man_3,
            alt: "car repair man 3"
        },
        car_repair_smoke: {
            src: car_repair_smoke,
            alt: "car repair smoke"
        },
        car_with_broken_glass_1: {
            src: car_with_broken_glass_1,
            alt: "car with broken glass 1"
        },
        car_with_broken_glass_2: {
            src: car_with_broken_glass_2,
            alt: "car with broken glass 2"
        },
        car_with_broken_glass_3: {
            src: car_with_broken_glass_3,
            alt: "car with broken glass 3"
        },
        car_with_people_1: {
            src: car_with_people_1,
            alt: "car with people 1"
        },
        car_with_people_2: {
            src: car_with_people_2,
            alt: "car with people 2"
        },
        car_with_people_3: {
            src: car_with_people_3,
            alt: "car with people 3"
        },
        collision_with_bike_1: {
            src: collision_with_bike_1,
            alt: "collision with bike 1"
        },
        collision_with_bike_2: {
            src: collision_with_bike_2,
            alt: "collision with bike 2"
        },
        collision_with_bike_3: {
            src: collision_with_bike_3,
            alt: "collision with bike 3"
        },
        family_1: {
            src: family_1,
            alt: "family 1"
        },
        family_2: {
            src: family_2,
            alt: "family 2"
        },
        family_3: {
            src: family_3,
            alt: "family 3"
        },
        fire: {
            src: fire,
            alt: "fire"
        },
        man_with_umbrella_1: {
            src: man_with_umbrella_1,
            alt: "man with umbrella 1"
        },
        man_with_umbrella_2: {
            src: man_with_umbrella_2,
            alt: "man with umbrella 2"
        },
        man_with_umbrella_3: {
            src: man_with_umbrella_3,
            alt: "man with umbrella 3"
        },
        smoke: {
            src: smoke,
            alt: "smoke"
        },
        thief_1: {
            src: thief_1,
            alt: "thief 1"
        },
        thief_2: {
            src: thief_2,
            alt: "thief 2"
        },
        thief_3: {
            src: thief_3,
            alt: "thief 3"
        },
        traffic_light_green: {
            src: traffic_light_green,
            alt: "traffic light green"
        },
        traffic_light_orange: {
            src: traffic_light_orange,
            alt: "traffic light orange"
        },
        traffic_light_red: {
            src: traffic_light_red,
            alt: "traffic light red"
        },
        tree_1: {
            src: tree_1,
            alt: "tree 1"
        },
        tree_2: {
            src: tree_2,
            alt: "tree 2"
        },
        tree_3: {
            src: tree_3,
            alt: "tree 3"
        },
        white_smoke: {
            src: white_smoke,
            alt: "white smoke"
        },
        woman_phoning_1: {
            src: woman_phoning_1,
            alt: "woman phoning 1"
        },
        woman_phoning_2: {
            src: woman_phoning_2,
            alt: "woman phoning 2"
        },
        woman_phoning_3: {
            src: woman_phoning_3,
            alt: "woman phoning 3"
        },
        woman_walking_1: {
            src: woman_walking_1,
            alt: "woman walking 1"
        },
        woman_walking_2: {
            src: woman_walking_2,
            alt: "woman walking 2"
        },
        woman_walking_3: {
            src: woman_walking_3,
            alt: "woman walking 3"
        }
    }
    return srcs[id]
}