import { FC, ReactElement, useState, useEffect } from "react"
import styled, { css, CSSProperties, FlattenSimpleInterpolation } from "styled-components"
import { breakpoint } from "../../styles"
import { _position_absolute, _PositionAbsolute } from "../../styles/mixins"
import { ImageId, FrameId, FrameData } from "../../types"
import { getSvgById, getFrame } from "../../utils"

export interface ImageProps {
    imageId?: ImageId
    frameId?: FrameId
    width?: CSSProperties["width"]
    smWidth?: string
    height?: string
    position?: _PositionAbsolute
    z?: CSSProperties["zIndex"]
    anim?: FlattenSimpleInterpolation
    styles?: CSSProperties
}

/** Displayes a stored svg prop according to its id */
const Img = styled.img<Pick<ImageProps, "width" | "smWidth" | "height" | "position" | "z"|"anim">>`
    ${props => props.z && css`z-index: ${props.z};`};
    ${props => props.height && css`height: ${props.height};`};
    ${props => props.position && _position_absolute(props.position)};
    ${props => props.anim && props.anim};
    ${props => props.width && css`width: ${props.width};`};
    @media (max-width: ${breakpoint}px) {
        ${props => props.smWidth && css`width: ${props.smWidth};`};
    }
    `

/** Display svg prop, without tha ability to change its color */
export const Image: FC<ImageProps> = ({
    imageId,
    frameId,
    position = undefined,
    width = undefined,
    smWidth = undefined,
    height = undefined,
    anim = undefined,
    z = 10,
    styles,
}): ReactElement | null => {
    const [svgData, setSvgData] = useState<FrameData>()
    useEffect(
        () => {
            if(imageId) setSvgData(getSvgById(imageId))
            if(frameId) setSvgData(getFrame(frameId))

        }, [imageId, frameId]
    )

    return svgData ? <Img
        z={z}
        width={width}
        smWidth={smWidth}
        height={height}
        position={position}
        anim={anim}
        src={svgData.src}
        alt={svgData.alt}
        style={styles}
    /> : null
}