import styled, { css } from "styled-components"
import { breakpoint } from "../../styles"

interface StrongProps {
    double?: boolean
    bigger?: boolean
}

/** Displays a bold text (inline) */
export const Strong = styled.strong<StrongProps>`
    font-weight: bold;
    color: inherit;
    ${props => (props.double || props.bigger) && css`
        font-size: ${props.double ? 200 : 140}%;
        ${props.double && `padding-right: 10px`};
        margin: 0;
        line-height: 30px;
        @media (max-width: ${breakpoint}px) {
            line-height: 6vw;
        }
    `};
`