import { FC, ReactElement } from "react"
import styled, { css } from "styled-components"
import { theme, breakpoint } from "../../styles"
import { _font_size } from "../../styles/mixins"

interface HeadlineProps {
    sub?: "h2" | "h3" | "h4"
    color?: string
    notBold?: boolean
    inBalloon?: boolean
    inAdviceButton?: boolean
    inAdviceHeader?: boolean
    inDetailsBox?: boolean
    uppercase?: boolean
}

//* consts
const headline_sizes = {
    h1: 48,
    h2: 44,
    h3: {
        balloon: 18,
        button: "1rem",
    },
    h4: {
        adviceButton: 18,
        adviceHeader: 42,
        detailsBox: 26,
    }
}

/** Displays a heading label */
export const Headline: FC<HeadlineProps> = ({
    sub = undefined,
    color = undefined,
    notBold = false,
    inAdviceButton = false,
    inAdviceHeader = false,
    inDetailsBox = false,
    uppercase = false,
    inBalloon = false,
    children,
}): ReactElement => (
    <StyledHeadline
        sub={sub}
        color={color}
        notBold={notBold}
        uppercase={uppercase}
        inBalloon={inBalloon}
        inAdviceButton={inAdviceButton}
        inAdviceHeader={inAdviceHeader}
        inDetailsBox={inDetailsBox}
        {...(sub && { as: sub })}
    >
        {children}
    </StyledHeadline>
)

/** Styled components */

const StyledHeadline = styled.h1<HeadlineProps>`
    white-space: pre-line;
    text-align: center;
    user-select: none;

    ${props => props.uppercase && css`text-transform: uppercase;`};

    /* color */
    color: ${props => theme.text[!props.sub ? "headline" : "general"]};    
    ${props => props.color && css`color: ${props.color};`};

    /* font-weight */
    font-weight: 700;
    ${props => (!props.sub || ["h2", "h4"].includes(props.sub)) && css`
        font-weight: 900;
    `};
    ${props => props.notBold && css`font-weight: normal;`};
    ${props => !props.sub && css`
        margin: 30px 0;
        font-weight: 1000;
    `};

    
    /* font-size */
    ${props => !props.sub && _font_size(headline_sizes.h1)}
    @media (max-width: ${breakpoint}px) {
        ${props => !props.sub && css`font-size: 6vw`}
    }

    ${props => props.inBalloon && css`
        ${_font_size(headline_sizes.h3.balloon)};
        padding: 5px 20px;
        margin-bottom: 15px;
    `};

    /* h4 params*/
    ${props => props.inAdviceButton && css`
        ${_font_size(headline_sizes.h4.adviceButton)};
        color: ${theme.text.detailsButton};
        text-align: center;
    `}
    ${props => props.inDetailsBox && css`
        ${_font_size(headline_sizes.h4.detailsBox)};
        color: ${theme.text.detailsBox};
        text-align: left;
        `}
        ${props => props.inAdviceHeader && css`
        ${_font_size(headline_sizes.h4.adviceHeader)};
        font-weight: 1000;
        letter-spacing: .5px;
        color: ${theme.text.general};
        text-align: left;
    `}
`