import { CSSProperties, FC, Fragment, ReactElement } from "react"
import { WithCategory } from "../../types"
import { ImageId } from "../../types"
import { PageLayout } from "../templates/PageLayout"

import { NavRibbon } from "../organisms/NavRibbon"
import { BalloonProps } from "../molecules/Balloon"
import { Image } from "../atoms/Image"
import { Sprite } from "../atoms/Sprite"
import { Frame } from "../molecules/Frame"
import { 
    useSprites, SpriteDataset, 
    useImages, ImageDataset, 
    useFrames, FrameDataset,
    useMobileView,
} from "../../hooks"

interface SceneTemplateProps extends Partial<WithCategory> {
    bgId: ImageId
    bgSm?: ImageId
    smAr: number
    styles?: CSSProperties
    balloons?: BalloonProps[]
    spritesDataset: SpriteDataset[]
    imagesDataset: ImageDataset[]
    framesDataset: FrameDataset[]
}

export const SceneTemplate: FC<SceneTemplateProps> = ({
    bgId,
    bgSm = "bg_sm_home",
    smAr,
    category,
    balloons,
    styles,
    imagesDataset,
    spritesDataset,
    framesDataset,
    children,
}): ReactElement => {
    const isMobileView = useMobileView()
    const sprites = useSprites(spritesDataset)
    const frames = useFrames(framesDataset)
    const images = useImages(imagesDataset)

    const sceneStyles = {
        ...styles
    }
    const bubbleData = typeof category === "undefined" ? undefined : {
        category,
        position: {
            x: 10,
            y: 20,
            centerTransform: true
        }
    }
    return (
        <PageLayout bgId={bgId} bgSm={bgSm} smAr={smAr} bubble={bubbleData} styles={sceneStyles} isDetails={false}>
            {
                category && (
                    <NavRibbon
                        category={category}
                        balloons={balloons}
                    />
                )
            }
            {
                (!isMobileView) && (
                    <Fragment>
                        { images.map( (image, pIdx) => <Image key={pIdx} {...image}/>) }
                        { sprites.map((sprite, sIdx) => <Sprite key={sIdx} {...sprite}/>) }
                        { frames.map( (frame, fIdx) => <Frame key={fIdx} {...frame}/>) }
                    </Fragment>
                )
            }

            { children }
        </PageLayout>
    )
}