import styled, { css } from "styled-components"
import { WithCategory } from "../../types"
import { breakpoint, theme } from "../../styles"
import { getSvgById } from "../../utils"

interface DoubleQuoteIconProps extends WithCategory {
    isEnd?: boolean
    width?: string
    inline?: boolean
}

/** Icon with parametrable color for double quotes */
export const DoubleQuoteIcon = styled.div<DoubleQuoteIconProps>`
    ${props => css`
        z-index: 12;
        width: ${props.width ? props.width : "3.6%"};
        @media (max-width: ${breakpoint}px) {
            width: 5vw;
        }

        aspect-ratio: 1.562;
        margin-bottom: 1.4%;
        background-color: ${theme[props.category].quote};
        mask: url(${getSvgById(`quote_${props.isEnd ? "end" : "start"}`).src});
        mask-repeat: no-repeat;
        ${props.inline && css`
            display: inline-block;
            position: relative;
            top: 7%;
            margin-bottom: 0;
        `}
    `}
`