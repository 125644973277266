import styled from "styled-components"
import { WithCategory } from "../../types"
import { breakpoint, theme } from "../../styles"
import { getSvgById } from "../../utils"

/** Icon with parametrable color for home button */
export const HomeIcon = styled.div<WithCategory>`
    aspect-ratio: 1.117209302;
    width: 75%;
    width: 38px;
    height: 38px;
    margin: auto;
    background-color: ${props => theme[props.category].home};
    mask: url(${getSvgById("house").src});
    mask-repeat: no-repeat;
    
    @media (max-width: ${breakpoint}px) {
        width: 7.3vw;
        height: 7.3vw;
    }
`