import { FC, ReactElement } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"

import { Image } from "../atoms/Image"
import { Headline } from "../atoms/Headline"
import { Text } from "../atoms/Text"
import { DetailsTypeButton } from "../molecules/DetailsTypeButton"

import { _flexbox, _position_absolute, _font_size } from "../../styles/mixins"
import { theme } from "../../styles/theme"
import { Category, NavButton, BalloonSize } from "../../types"
import { getSvgById } from "../../utils/getImage"
import { useMobileView } from "../../hooks"

export interface BalloonProps extends NavButton {
    size: BalloonSize
    category: Category
    x: number
    y: number
}

//* CONSTANTS
const balloonWidth = "180px"
const balloonHeights: { [key in BalloonProps["size"]]: string } = {
    xs: "31%",
    sm: "34%",
    md: "38%",
    lg: "380px",
    xl: "50%",
}
const more = "En savoir plus"
//* CONSTANTS END

/** UI element which links to corresponding Details view */
export const Balloon: FC<BalloonProps> = ({
    link,
    size,
    category,
    x,
    y,
    label,
}): ReactElement => {
    const isMobileView = useMobileView()
    return isMobileView ? (
        <DetailsTypeButton
            category={category}
            link={link}
            onClick={() => {
                //@ts-ignore
                OnCategoryPageEvent(link);
            }}
        >
            {label}
            <Image imageId="arrow_right" width="5vw"/>
        </DetailsTypeButton>
    ) : (
        <Container size={size} x={x} y={y}>
            <section>
                <Top
                    to={link}
                    category={category}
                    onClick={() => {
                        //@ts-ignore
                        OnCategoryPageEvent(link);
                    }}
                >
                    <Headline sub="h3" inBalloon>{label}</Headline>
                    <Text bold uppercase color={theme.text.balloon.secondary}>
                        {more}
                    </Text>
                    <Image imageId="arrow_down" width="8px" />
                </Top>
            </section>
            <Thread category={category} />
            <div style={{width: "100%", margin: 0, padding: 0}}>
                <Base category={category} />
            </div>
        </Container>
    )
}
/** Styled components */

const Container = styled.hgroup<Pick<BalloonProps, "size" | "x" | "y">>`
    margin: 0;
    padding: 0;
    width: ${balloonWidth};
    height: ${props => balloonHeights[props.size]};
    ${_flexbox({ fd: "column" })};
    transform: translate(-50%, -100%);
    ${props => _position_absolute({ x: props.x, y: props.y })};
    z-index: 100;
`
const Top = styled(Link)<Pick<BalloonProps, "category" | "onClick">>`
    width: 100%;
    aspect-ratio: 0.850482713;
    height: calc(${balloonWidth} / 0.850482713);
    mask: url(${getSvgById("balloon").src});
    mask-repeat: no-repeat;
    background-color: ${props => theme[props.category].balloon};
    font-family: Gotham_Rounded;
    ${_font_size(12)};
    color: ${theme.text.general};
    font-weight: bold;
    ${_flexbox({ fd: "column", jc: "flex-end", ai: "center" })};
    padding-bottom: 20%;
    text-decoration: none;
    gap: 1%;
`
const Thread = styled.div<Pick<BalloonProps, "category">>`
    margin: 0;
    padding: 0;    
    position: relative;
    top: -.75%;
    height: 100%;
    width: 2%;
    background-color: ${props => theme[props.category].balloon};
`
const Base = styled.div<Pick<BalloonProps, "category">>`
    aspect-ratio: 1;
    width: 38px;
    height: 38px;
    position: relative;
    margin: auto;
    top: -10%;
    background-color: ${props => theme[props.category].balloon};
    border-radius: 100%;
`