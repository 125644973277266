import { keyframes } from "styled-components"

export const drop = (
    height: number, 
    pos = Math.random() < .5 ? .85 : .75
) => keyframes`
    0% { transform: translate(0px, 0%); }
    80% { transform: translate(0px, ${ height*pos }px); }
    100% { transform: translate(0px, ${ height*pos }px); }
`

export const stem = keyframes`
      0% { opacity: 1; }
     95% { opacity: 1; }
     98% { opacity: 0; }
    100% { opacity: 0; }
`

export const splat = keyframes`
    0% {
        opacity: 1;
        transform: scale(0);
    }
    80% {
        opacity: 1;
        transform: scale(0);
    }
    90% {
        opacity: 0.5;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1.5);
    }
`