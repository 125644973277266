import { FC, ReactElement, useEffect, useRef, useState } from "react"
import { Container, Drop, Splat, Stem } from "./styled/components"

interface RainProps {
    duration?: string
}

export const Rain: FC<RainProps> = ({ duration = ".4s" }): ReactElement => {
    const [content, setContent] = useState<[number, number, number][]>([])
    const [containerHeight, setContainerHeight] = useState<number>(0)
    const ref = useRef<HTMLElement>(null)

    useEffect(() => {
        const rain: [number, number, number][] = []
        for (let i = 3; i < 100; i += 0) {
            const randomFiver: number = Math.floor(Math.random()*3) + 1
            const randomHundo: number = Math.floor(Math.random()*98) + 1
            rain.push([i, randomFiver, randomHundo])
            i += randomFiver
        }
        setContent(rain)
        setContainerHeight(ref?.current?.clientHeight || 0)
    }, [])
    
    return (
        <Container ref={ref}>
            {
                content.map(
                    ([i, fiver, hundo]) => (
                        <Drop
                            key={i}
                            i={i}
                            fiver={fiver}
                            hundo={hundo}
                            containerHeight={containerHeight}
                            duration={duration}
                        >
                            <Stem duration={duration} fiver={fiver} hundo={hundo} />
                            <Splat duration={duration} hundo={hundo} />
                        </Drop>
                    )
                )
            }
        </Container>
    )
}