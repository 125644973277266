import { FC, ReactElement } from "react"
import styled, { css } from "styled-components"
import { Category } from "../../types"
import { breakpoint, theme } from "../../styles"
import { DetailsBoxHeader } from "../molecules/DetailsBoxHeader"
import { COMMON_LOCALES } from "../../_config/common_locales"

/** Generic block used in Details views to display contract & data keys information */
export const DetailsBoxContainer: FC<DetailsBoxContainerProps> = ({
    category,
    type,
    children,
}): ReactElement => (
    <BoxShape category={category} type={type}>
        <DetailsBoxHeader>{COMMON_LOCALES.detailsHeadlines[type]}</DetailsBoxHeader>
        { children }
    </BoxShape>
)

/** REST */

export interface DetailsBoxContainerProps {
    category: Category
    type: "contract" | "datakeys"
}

const BoxShape = styled.section<Pick<DetailsBoxContainerProps, "category" | "type">>`
    background-color: ${props => theme[props.category][props.type]};
    position: relative;
    width: 100%;
    margin-bottom: 2%;
    @media (max-width: ${breakpoint}px) {
        margin-top: 7vw;
        margin-bottom: 0;
        ${props => props.type === "contract" && css`
            padding-bottom: 12vw;
        `}
    }
`