import { ReactElement, Fragment } from "react"
import { Strong } from "../components/atoms/Strong"
import { DetailsTemplate } from "../components/templates/DetailsTemplate"
import { COMMON_LOCALES } from "../_config/common_locales"

export const OthersDetails = (): ReactElement => (
    <DetailsTemplate
        category="crash"
        label={COMMON_LOCALES.labels.crash.others}
        quote={{
            strings: ["Je freine trop tardivement et percute le véhicule à l'avant:\nles occupants de l'autre véhicule sont blessés ainsi que le piéton qui se trouvait sur le trottoir"],
            imageId: "carcrash_with_injury_man",
            propWidth: "35%",
            smWidth: "55vw",
        }}
        datakeys={{
            source: "IPSOS - VINCI AUTOROUTE - Mai 2020 & GMF Assurances - 2017",
            list: [
                {
                    percentage: 93,
                    subject: "des piétons ont assuré qu'un automobiliste ne s'arrête pas systématiquement alors qu'il s'engage pour traverser.",
                    imageId: "man_walking"
                },
                {
                    percentage: 71,
                    subject: "des automobilistes estiment difficile de partager la route avec des piétons.",
                    imageId: "man_on_crosswalk"
                }
            ]
        }}
        contract={
            <Fragment>
                Il s'agit de la <Strong>Responsabilité civile, garantie obligatoire dans un contrat automobile.</Strong> Elle indemnise les victimes des dommages corporels et matériels subis.
            </Fragment>
        }
        advice={[
            <Fragment>
                <Strong>Soyez attentifs aux autres usagers de la route :</Strong> respect des vitesses dans les espaces partagés avec les motos, trottinettes, vélos.
            </Fragment>,
            <Fragment>
                <Strong>Respectez les limitations de vitesse et les distances de sécurité</Strong> surtout en cas de pluie, de brouillard ou de verglas.
            </Fragment>
        ]}
    />
)