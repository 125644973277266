import styled from "styled-components"
import { breakpoint } from "../../styles"

/** Displays a datakeys section source in Details views */
export const Source = styled.cite`
    font-family: Gotham;
    font-style: italic;
    font-size: 12px;
    display: block;
    text-align: right;
    padding: 5px 15px;
    letter-spacing: .5px;
    ::before {
        content: "Sources : ";
    }

    @media (max-width: ${breakpoint}px) {
        /* width: 100vw; */
        font-size: 2vw;
        padding: 5vw 2vw;
        margin: 0;
        text-align: left;
    }
`