import { FC, ReactElement, CSSProperties } from "react"
import styled from "styled-components"
import { breakpoint, theme } from "../../styles"
import { _flexbox, _font_size } from "../../styles/mixins"
import { Headline } from "../atoms/Headline"
import { AdviceIcon } from "../atoms/AdviceIcon"
import { WithCategory } from "../../types"
import { COMMON_LOCALES } from "../../_config/common_locales"


interface AdviceButtonProps extends WithCategory {
    handleClick: () => void,
    style?: CSSProperties
}

/** UI clickable button in Details views used to access company advice */
export const AdviceButton: FC<AdviceButtonProps> = ({
    category,
    handleClick,
    style,
}): ReactElement => (
    <Container style={style} onClick={handleClick}>
        <AdviceIcon category={category} />
        <Headline sub="h4" uppercase inAdviceButton>{COMMON_LOCALES.our_advice}</Headline>
    </Container>
)

/** Styled components */

const Container = styled.button`
    ${_flexbox({ fd: "column", jc: "center" })}
    background-color: ${theme.advice.button};
    padding: .5%;
    border-radius: 10px;
    width: 7%;
    aspect-ratio: 1;
    font-weight: 900;
    ${_font_size(18)}
    border: none;
    text-decoration: none;
    cursor: pointer;
    transform: translateX(180px);
    margin-bottom: 50px;
    @media (max-width: ${breakpoint}px) {
        transform: translateX(0px);
        width: 20vw;
        margin: 6vw auto;
    }
`