import { CSSProperties } from "react"
import { css, keyframes } from "styled-components"

const rotation = (from: string, to: string) => keyframes`
    from { 
        transform-origin: 40% 100%;
        transform: rotate(${from}); 
    }
    to { 
        transform-origin: 40% 100%;
        transform: rotate(${to}); 
        }
`

export const rotateSprite = (
    duration: number, 
    from: string = "0deg", 
    to: string = "15deg",
    direction: CSSProperties["animationDirection"] = "alternate",
    repeat: CSSProperties["animationIterationCount"] = "infinite",
    timing: CSSProperties["animationTimingFunction"] = "linear",
) => css`
    animation: ${rotation(from, to)} ${duration}s ${timing} ${direction} ${repeat};
`