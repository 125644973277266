import { useEffect, useState } from "react"
import { 
    css,
    SimpleInterpolation,
    FlattenSimpleInterpolation,
} from "styled-components"

export function useAnimation(...anims: FlattenSimpleInterpolation) {
    const [animations, setAnimations] = useState<FlattenSimpleInterpolation>(css``)
    useEffect(
        () => {
            const x: SimpleInterpolation[] = [
                "animation: ",
            ]

            anims.filter(Array.isArray).forEach(
                (filtered, animIdx, all) => {
                    filtered.forEach(
                        (f, fieldIdx) => {
                            if (fieldIdx !== 0 && f !==";") x.push(f)
                        }
                    )
                    x.push(animIdx !== all.length - 1 ? ", ":"; ")
                }
            )
            setAnimations(x as FlattenSimpleInterpolation)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    )
    return animations
}