import styled, { css } from "styled-components"
import { breakpoint, theme } from "../../styles"
import { _font_size } from "../../styles/mixins"

interface TextProps {
    bold?: boolean
    uppercase?: boolean
    color?: string
    centered?: boolean
    inContractBox?: boolean
    inSimpleDatakey?: boolean
}

/** Displays a text (block) */
export const Text = styled.p<TextProps>`
    ${props => css`
        letter-spacing: 1px;
        white-space: pre-line;
        color: ${props.color ? props.color : theme.text.general};
        font-weight: ${props.bold ? "bold" : "normal"};
        ${props.uppercase && css`text-transform: uppercase;`}
        ${props.centered && css`text-align: center;`}
        ${props.inContractBox && css`
            ${_font_size(26)}
            @media (max-width: ${breakpoint}px) {
                font-size: 3.8vw;
            }
        `};
        ${props.inSimpleDatakey && _font_size(28)};
    `}
    ${props => (props.inContractBox || props.inSimpleDatakey) && css`
        text-align: justify;
        padding-bottom: ${props.inContractBox ? 2.8 : 1.4}%;
        padding-left: 3%;
        ${props.inContractBox && "width: 80%"};
        ${props.inSimpleDatakey && "width: 70%"};

        @media (max-width: ${breakpoint}px) {
            font-size: 3.8vw;
        }
    `}
`