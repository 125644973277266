import styled from "styled-components"
import { breakpoint, theme } from "../../styles"

interface AdviceItemProps {
    /** Color depending on category, used as diti background-color */
    color: string
    /** Corresponding counter name */
    counter: string
}

/** UI block in Advice views */
export const AdviceItem = styled.li<AdviceItemProps>`
    position: relative;
    color: ${theme.text.general};
    background-color: ${theme.advice.item};
    counter-increment: ${props => props.counter};
    flex: 1;
    align-self: stretch;
    /* aspect-ratio: 1.25; */
    min-height: 400px;
    max-width: 600px;
    padding: 70px 30px;
    font-size: 30px;

    @media (max-width: ${breakpoint}px) {
        font-size: 5vw;
        padding: 12vw 4vw 25vw 4vw;
        min-height: auto;
        max-height: auto;
    }

    text-align: center;
    &::before {
        content: counter(${props => props.counter});
        transform: translate(-50%, -50%);
        position: absolute;
        top: 0;
        left: 50%;
        aspect-ratio: 1;
        width: 70px;
        text-align: center;
        background-color: ${props => props.color};
        border-radius: 50%;
        font-weight: bold;
        font-size: 40px;
        line-height: 70px;
        @media (max-width: ${breakpoint}px) {
            font-size: 10vw;
            width: 15vw;
            height: 15vw;
            line-height: 15vw;
        }
    }
`