import { FC, ReactElement } from "react"
import styled from "styled-components"

import { Headline } from "../atoms/Headline"
import { AdviceIcon } from "../atoms/AdviceIcon"

import { theme } from "../../styles"
import { WithCategory } from "../../types"
import { _flexbox } from "../../styles/mixins"

//! constants
const advice = "Nos conseils"

/** UI section header displayed in company advice views */
export const AdviceHeader: FC<WithCategory> = ({ category }): ReactElement => (
    <Container category={category}>
        <Headline sub="h4" uppercase inAdviceHeader>{advice}</Headline>
        <AdviceIcon category={category} style={{ width: 90, float: "right", margin: 0 }} />
    </Container>
)

/** Styled components */

const Container = styled.header<WithCategory>`
    border-bottom: 1px solid ${props => theme[props.category].quote};
    padding-bottom: 15px;
    ${_flexbox({ jc: "space-between" })}
`