import { Category } from "../types"

/** All application available routes */
export const ROUTES = {
    HOME: "/",
    CRASH: {
        SCENE: "/accidents",
        OTHERS: "/accidents/dommages_causes_aux_autres",
        CAR: "/accidents/dommages_causes_au_vehicule",
        DRIVER: "/accidents/dommages_causes_au_conducteur",
    },
    HAZARD: {
        SCENE: "/aleas",
        VANDALISM: "/aleas/vol_vandalisme_incendie",
        BROKEN_GLASS: "/aleas/bris_de_glace",
        WEATHER: "/aleas/tempete_grele_catastrophes_naturelles_et_technologiques",
    },
    BREAKDOWN: {
        SCENE: "/panne",
        PUNCTURE: "/panne/panne_mecanique_et_ou_crevaison",
    },
    LEGAL: "/mentions-legales",
    COOKIES: {
        POLICY: "/politique-des-cookies",
        MANAGEMENT: "#"
    }
}

export const SCENE_ROUTES: { [key in Category]: string } = {
    crash: "/accidents",
    hazard: "/aleas",
    breakdown: "/panne",
}