import { FC, ReactElement } from "react";
import { GenericBubble, DefinedBubbleProps } from "./GenericBubble";
import {
    getBubbleLink,
    getBubbleLabel,
    getBubbleIconId,
} from "../../../utils"

/** UI component which illustrates one of the given categories */
export const Bubble: FC<DefinedBubbleProps> = ({
    category,
    withLink = false,
    position = undefined
}): ReactElement => (
    <GenericBubble
        category={category}
        link={getBubbleLink(category)}
        iconId={getBubbleIconId(category)}
        label={getBubbleLabel(category)}
        withLink={Boolean(withLink)}
        position={position}
    />
)