import { css } from "styled-components";

/**
 * Sets correct sizes for `font-size` css rule
 * @param max Font size in px corresponding to value when width is max (1920px) 
 */
export function _font_size (max: number, forceMin?: number) {
    const MAX_WIDTH = 1920
    const MIN_WIDTH = 1200
    const relative: number = max / MAX_WIDTH * 100
    const min = relative * MIN_WIDTH / 100

    return css`
        font-size: ${forceMin || min}px;
        @media (min-width: ${MIN_WIDTH}px) and (max-width: ${MAX_WIDTH}px) {
            font-size: ${relative}vw;
        }
        @media (min-width: ${MAX_WIDTH}px) {
            font-size: ${max}px;
        }
    `
}