import { Category, ColouredElements } from "../types"
import { bluePalette, exoticPalette, orangePalette, redPalette } from "./palettes"

type Pr = {
    [key in Category]: { [key in ColouredElements]: string }
}

interface Theme extends Pr {
    advice: {
        item: string            // bgColor for Advice item
        button: string          // bgColor for Advice button
    }
    navButton: {
        backspace: string       // bgColor for Backspace button
        home: string            // bgColor for Home button 
    },
    detailsBox: {
        primary: string         // bgColor for detailBox header main section
        secondary: string       // bgColor for detailBox header protruding piece
        wrapper: string,        /** Background color for Details page wrapper */
    }
    text: {
        general: string
        headline: string
        bubble: string
        balloon: {
            primary: string
            secondary: string
        }
        detailsBox: string      // color for DetailsBox header text
        detailsButton: string   // color for DetailsButton text
    }
}

export const theme: Theme = {
    advice: {
        button: exoticPalette.white,
        item: exoticPalette.beige,
    },
    navButton: {
        backspace: exoticPalette.black,
        home: exoticPalette.white,
    },
    detailsBox: {
        primary: exoticPalette.black,
        secondary: exoticPalette.grey,
        wrapper: exoticPalette.white,
    },
    text: {
        general: exoticPalette.black,
        headline: exoticPalette.darkPurple,
        bubble: exoticPalette.white,
        balloon: {
            primary: exoticPalette.black,
            secondary: exoticPalette.white,
        },
        detailsBox: exoticPalette.white,
        detailsButton: exoticPalette.black,
    },
    crash: {
        bubble: orangePalette.medium,
        balloon: orangePalette.medium,
        home: orangePalette.medium,
        advice: orangePalette.medium,
        datakeys: orangePalette.thin,
        contract: orangePalette.light,
        quote: orangePalette.medium,
        button: orangePalette.medium,
    },
    hazard: {
        bubble: bluePalette.medium,
        balloon: bluePalette.medium,
        home: bluePalette.medium,
        advice: bluePalette.medium,
        datakeys: bluePalette.thin,
        contract: bluePalette.light,
        quote: bluePalette.medium,
        button: bluePalette.medium,
    },
    breakdown: {
        bubble: redPalette.medium,
        balloon: redPalette.medium,
        home: redPalette.medium,
        advice: redPalette.medium,
        datakeys: redPalette.thin,
        contract: redPalette.light,
        quote: redPalette.medium,
        button: redPalette.medium,
    }
}