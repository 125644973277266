import { FC, Fragment, ReactElement, useEffect, useState } from "react"
import { CSSProperties, FlattenSimpleInterpolation } from "styled-components"
import { _PositionAbsolute } from "../../styles/mixins"
import { FrameId } from "../../types"
import { Image, ImageProps } from "../atoms/Image"

export interface FrameProps extends Pick<ImageProps, "z">{
    duration: number
    isOneByOne: boolean
    frames: {
        id: FrameId
        position: _PositionAbsolute
        width: CSSProperties["width"]
        anim?: FlattenSimpleInterpolation
        styles?: CSSProperties
    }[]
}

export const Frame: FC<FrameProps> = ({
    frames,
    duration,
    isOneByOne,
    z = 10,
}): ReactElement => {
    const [currentFrameIdx, setFrameIdx] = useState<number>(0)

    useEffect(() => {
        const isLastFrame: boolean = currentFrameIdx === frames.length - 1
        if (!isLastFrame) {
            const delay: number = duration / frames.length
            const timer = setTimeout(() => {
                setFrameIdx(currentFrameIdx + 1)
            }, delay)

            return () => { clearTimeout(timer) }
        }
    }, [frames.length, currentFrameIdx, duration])

    return (
        <Fragment>
            {
                frames.map(
                    (frame, frameIdx) => (
                        <Image
                            z={z}
                            key={frameIdx}
                            frameId={frame.id}
                            anim={frame.anim}
                            width={frame.width}
                            position={frame.position}
                            styles={{
                                display: (!isOneByOne || currentFrameIdx === frameIdx) ? "block":"none",
                                ...frame.styles
                            }}
                        />
                    )
                )
            }

        </Fragment>
    )
}