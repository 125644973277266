import { FC, ReactElement } from "react"
import { DetailsBoxContainer } from "./DetailsBoxContainer"
import { WithCategory } from "../../types"
import { Source } from "../atoms/Source"
import { SimpleDatakey, SimpleDatakeyProps } from "../molecules/SimpleDatakey"
import { DatakeysList } from "./DatakeysList"
import { DatakeyItemProps } from "../molecules/DatakeyItem"

export interface DatakeysBoxProps extends WithCategory {
    list?: Omit<DatakeyItemProps, "color">[]
    simple?: SimpleDatakeyProps
    source: string
    text?: string
}

/** UI section displayed in Details view for information about data keys */
export const DatakeysBox: FC<DatakeysBoxProps> = ({
    category,
    source,
    list = undefined,
    simple = undefined,
    text = undefined,
    children
}): ReactElement => {
    return (
        <DetailsBoxContainer category={category} type="datakeys">
            {
                simple ? (
                    <SimpleDatakey
                        imageId={simple.imageId}
                        width={simple.width}
                        position={simple.position}
                    >
                        {children}
                    </SimpleDatakey>
                ) : list && <DatakeysList list={list} text={text} category={category} />
            }
            <Source>{source}</Source>
        </DetailsBoxContainer>
    )
}