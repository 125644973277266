import styled, { css } from "styled-components"
import { drop, stem, splat } from "./keyframes"

interface RainProps {
    i: number
    hundo: number
    fiver: number
    isBackdrop?: boolean
    containerHeight: number
    duration: string
}

function animDelayAndDuration(hundo: number) {
    return css`
        animation-delay: 0.${hundo}s;
    `
}

export const Drop = styled.div.attrs(() => ({
    role: "presentation"
}))<RainProps>`
    ${props => css`
        z-index: 20;
        width: 15px;
        height: 120px;
        position: absolute;
        bottom: ${(props.fiver * 2) - 1 + 100};
        ${props.isBackdrop ? "right":"left"}: ${props.i}%;
        animation: ${drop(props.containerHeight)} ${props.duration} linear infinite;
        ${animDelayAndDuration(props.hundo)}
    `}
`

export const Stem = styled.div.attrs(() => ({
    role: "presentation"
}))<Pick<RainProps, "hundo"|"fiver"|"duration">>`
    width: 3px;
    height: 60%;
    margin-left: 7px;
    opacity: 0;
    background: linear-gradient(
        to bottom, 
        rgba(255, 255, 255, 0), 
        rgba(255, 255, 255, 0.8)
    );
    animation: ${stem} ${props => props.duration} linear infinite;
    ${props => animDelayAndDuration(props.hundo)}
`

export const Splat = styled.div.attrs(() => ({
    role: "presentation"
}))<Pick<RainProps, "hundo"|"duration">>`
    width: 15px;
    height: 10px;
    border-top: 9px dotted rgba(255, 255, 255, .8);
    border-radius: 50%;
    opacity: 1;
    transform: scale(0);
    animation: ${splat} ${props => props.duration} linear infinite;
    ${props => animDelayAndDuration(props.hundo)}
`

export const Container = styled.article.attrs(() => ({
    role: "presentation"
}))`
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
`