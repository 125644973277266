import { FC, ReactElement } from "react"
import styled from "styled-components"
import { Text } from "../atoms/Text"
import { _flexbox, _PositionAbsolute } from "../../styles/mixins"
import { Image } from "../atoms/Image"
import { ImageId } from "../../types"
import { useMobileView } from "../../hooks"

export interface SimpleDatakeyProps {
    imageId: ImageId
    width?: string
    smWidth?: string
    height?: string
    position?: _PositionAbsolute
}

/** Ui block used in datakeys section of Details views when content isn't a list */
export const SimpleDatakey: FC<SimpleDatakeyProps> = ({
    imageId,
    width = "9%",
    smWidth = "17vw",
    height,// = "100px",
    position,
    children,
}): ReactElement => {
    const isMobileView = useMobileView()
    const mobilePos: _PositionAbsolute = {
        x: 95,
        y: 50,
        customTransform: ["-100%", "-50%"]
    }
    return (
        <Container>
            <Text inSimpleDatakey>{ children }</Text>
            <Image
                imageId={imageId}
                width={width}
                smWidth={smWidth}
                height={height}
                position={isMobileView ? mobilePos : position}
            />
        </Container>
    )
}
/** Styled components */

const Container = styled.section`${_flexbox({ jc: "flex-start" })}`