import { ReactElement } from "react"
import { BalloonProps } from "../components/molecules/Balloon"
import { SceneTemplate } from "../components/templates/SceneTemplate"
import { 
    SpriteDataset, 
    ImageDataset, 
    FrameDataset 
} from "../hooks"
import { dispelSmoke, moveCloud } from "../styles/anims"
import { COMMON_LOCALES } from "../_config/common_locales"
import { ROUTES } from "../_config/routes"

export const BreakdownScene = (): ReactElement => {
    const balloons: BalloonProps[] = [
        {
            category: "breakdown",
            size: "md",
            label: COMMON_LOCALES.labels.breakdown.puncture,
            link: ROUTES.BREAKDOWN.PUNCTURE,
            x: 36,
            y: 57,
        }
    ]

    const sprites: SpriteDataset[] = [
        ["woman_phoning", 10, "12.2%", 24.5, 45, true, "3s", "infinite"],
        ["car_repair_man", 11, "14.6%", 39, 45, true, "2s", 1],
    ]

    const images: ImageDataset[] = [
        ["cloud_reversed", 9, "18.75%", 63, 25, true, moveCloud("-200px", "100px")],
        ["sun", 8, "14%", 70, 18, true, ]
    ]

    const frames: FrameDataset[] = [
        [10, 3000, true, [
            ["car_repair_1", "51%", { x: 83, y: 73, customTransform: ["-100%", "-100%"] }],
            ["car_repair_2", "46%", { x: 83, y: 73, customTransform: ["-100%", "-100%"] }],
            ["car_repair_3", "42.6%", { x: 83, y: 73, customTransform: ["-100%", "-100%"] }],
        ]],
        [9, 1000, false, [
            ["car_repair_smoke", "0%", { x: 58, y: 50 }, dispelSmoke("3s")],
            ["car_repair_smoke", "0%", { x: 58, y: 50 }, dispelSmoke("7s")],
        ]]
    ]

    return (
        <SceneTemplate
            category="breakdown"
            bgId="bg_breakdown"
            bgSm="bg_sm_breakdown"
            smAr={1.05127573}
            balloons={balloons}
            imagesDataset={images}
            spritesDataset={sprites}
            framesDataset={frames}
        />
    )
}