import { ReactElement, Fragment } from "react"
import styled from "styled-components"
import { Strong } from "../components/atoms/Strong"
import { Text } from "../components/atoms/Text"
import { DetailsTemplate } from "../components/templates/DetailsTemplate"
import { _font_size } from "../styles/mixins"
import { COMMON_LOCALES } from "../_config/common_locales"

export const VandalismDetails = (): ReactElement => (
    <DetailsTemplate
        category="hazard"
        label={COMMON_LOCALES.labels.hazard.vandalism}
        quote={{
            strings: [
                "On a fracturé la serrure de mon véhicule et cassé la vitre passager."
            ],
            imageId: "thief_carjacking",
            propWidth: "17%"
        }}
        datakeys={{
            source: "Ministère de l'Intérieur 2019",
            simple: {
                imageId: "carjack_with_chrono",
                position: { x: 70, y: 50, centerTransform: true },
                width: "13%"
            },
            textIfSimple: (
                <Fragment>
                    <Strong>1 voiture volée toutes les 5 minutes</Strong> en France.
                </Fragment>
            )
        }}
        unwrapContract
        contract={
            <Fragment>
                <Text inContractBox style={{ paddingBottom: 0 }}>
                    <Strong>Le vol et le vandalisme sont 2 garanties distinctes liées à 2 actes différents</Strong>
                </Text>
                <Ul>
                    <li>L’un occasionne la disparition du véhicule</li>
                    <li>L’autre est un acte de dégradation du véhicule</li>
                </Ul>
                <Text inContractBox>
                    <Strong>Véhicule volé ou incendié,</Strong> vérifiez le niveau d’indemnisation prévu dans le contrat (indemnisation de la valeur expertisée du véhicule ou indemnisation majorée) et si vous pouvez bénéficier d’un véhicule de prêt.
                </Text>
            </Fragment>
        }
        adviceLink="#"
        advice={[
            <Fragment><Strong>Ne laissez jamais vos clés de contact et votre carte grise</Strong> dans votre voiture.</Fragment>,
            <Fragment><Strong>Evitez de stationner votre véhicule dans des endroits sombres et isolés.</Strong></Fragment>,
            <Fragment><Strong>Fermez vos portières à clés</Strong> et ne laissez pas d’objets de valeur apparents (GPS, sacs, manteaux, bijoux…).</Fragment>,
        ]}
    />
)

const Ul = styled.ul`
    margin: 15px 40px;
    ${_font_size(24)};
    & > li {
        list-style-type: "- ";
    }
`