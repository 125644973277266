import { useEffect, useState } from "react"
import { CSSProperties, FlattenSimpleInterpolation } from "styled-components"
import { FrameProps } from "../components/molecules/Frame"
import { _PositionAbsolute } from "../styles/mixins"
import { FrameId } from "../types"

// Parses page frames dataset to <Frame> props object
export function useFrames(dataset: FrameDataset[]): FrameProps[] {
    const [props, setProps] = useState<FrameProps[]>([])
    useEffect(() => {
        setProps(
            dataset.map(
                ([z, duration, isOneByOne, anims]) => ({
                    frames: anims.map(a => ({
                        id: a[0],
                        width: a[1],
                        position: a[2],
                        anim: a[3],
                        styles: a[4],
                    })),
                    duration,
                    isOneByOne,
                    z,
                })
            )
        )
    }, [dataset])

    return props
}

export type FrameDataset = [
    z: CSSProperties["zIndex"],
    duration: number,
    isOneByOne: boolean,
    frames: Array<[
        id: FrameId,
        width: CSSProperties["width"],
        position: _PositionAbsolute,
        animation?: FlattenSimpleInterpolation,
        styles?: CSSProperties
    ]>
]