import { useEffect, useState } from "react"
import { ImageProps } from "../components/atoms/Image"

// Parses page sprites dataset to <Sprite> props object
export function useImages(dataset: ImageDataset[]): ImageProps[] {
    const [props, setProps] = useState<ImageProps[]>([])
    useEffect(() => {
        setProps(
            dataset.map(
                ([imageId, z, width, x, y, i, anim]) => ({
                    imageId,
                    position: { x, y, i },
                    width,
                    z,
                    anim,
                })
            )
        )
    }, [dataset])

    return props
}

export type ImageDataset = [
    imageId: ImageProps["imageId"],
    z: ImageProps["z"],
    width: ImageProps["width"],
    x: NonNullable<ImageProps["position"]>["x"],
    y: NonNullable<ImageProps["position"]>["y"],
    i: NonNullable<ImageProps["position"]>["i"],
    anim?: ImageProps["anim"],
]