import { ReactElement } from "react"
import { SceneTemplate } from "../components/templates/SceneTemplate"
import { Rain } from "../components/molecules/Rain"
import { fireSmoke, glitteringFire, lightningThunder, moveCloud, rotateSprite } from "../styles/anims"
import { FrameDataset, ImageDataset, SpriteDataset, useMobileView } from "../hooks"
import { COMMON_LOCALES } from "../_config/common_locales"
import { ROUTES } from "../_config/routes"
import { BalloonProps } from "../components/molecules/Balloon"

export const HazardScene = (): ReactElement => {
    const isMobileView = useMobileView()
    const balloons: BalloonProps[] = [
        {
            category: "hazard",
            size: "md",
            label: COMMON_LOCALES.labels.hazard.vandalism,
            link: ROUTES.HAZARD.VANDALISM,
            x: 32.5,
            y: 59.5,
        }, {
            category: "hazard",
            size: "xl",
            label: COMMON_LOCALES.labels.hazard.broken_glass,
            link: ROUTES.HAZARD.BROKEN_GLASS,
            x: 47.5,
            y: 68,
        }, {
            category: "hazard",
            size: "sm",
            label: COMMON_LOCALES.labels.hazard.weather,
            link: ROUTES.HAZARD.WEATHER,
            x: 62,
            y: 64,
        },
    ]
    const images: ImageDataset[] = [
        ["big_cloud_2", 13, "7%", 81.5, 36, true, moveCloud("-200px", "100px", "8s")], // big blue cloud
        ["big_white_cloud", 9, "41.6%", 32.5, 5, true, moveCloud("-50px", "50px", "4s")], // white cloud with rain
        ["thunder", 7, "11%", 48.5, 20, true, lightningThunder("5s")], // middle thunder
        ["cloud_reversed", 10, "15%", 73, 32, true, moveCloud("-50px", "50px", "4s")], //right white cloud
        ["thunder", 9, "6%", 75, 42, true, lightningThunder("3s")], // right thunder
        ["blue_cloud", 11, "3%", 19, 47, true, moveCloud("-100px", "200px", "8s")], // left blue cloud
        ["thunder", 9, "4%", 14, 51, true, lightningThunder("3s")], // left thunder
        ["cloud", 10, "10%", 12, 45, true, moveCloud("-50px", "50px", "4s")] // left white cloud
    ]

    const sprites: SpriteDataset[] = [
        ["thief", 9, "15%", 28, 61, true, "1s", "infinite"],
        ["man_with_umbrella", 10, "21%", 62, 52, false, "1.2s", "infinite", rotateSprite(.6)],
        ["car_with_broken_glass", 10, "20.5%", 34.5, 66, true, "4s", 1],
        
    ]

    const frames: FrameDataset[] = [
        [9, 3000, false, [
            ["fire", "3%", { x: 41.5, y: 60 }, fireSmoke()],
            ["fire", "3%", { x: 41.5, y: 60 }, glitteringFire()],
        ]],
        [9, 3000, true, [
            ["tree_1", "10.3%", {x: 61.8, y: 79.5 }],
            ["tree_1", "10.3%", {x: 61.8, y: 79.5 }],
            ["tree_2", "12%", {x: 59.2, y: 79.5 }],
            ["tree_3", "17%", {x: 53.7, y: 79.4 }],
        ]]
    ]
    
    return (
        <SceneTemplate
            category="hazard"
            bgId="bg_hazard"
            bgSm="bg_sm_hazard"
            smAr={1.231222539}
            balloons={balloons}
            imagesDataset={images}
            spritesDataset={sprites}
            framesDataset={frames}
        >
            { !isMobileView && <Rain/> }
        </SceneTemplate>
    )
}