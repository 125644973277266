import { FC, ReactElement } from "react"
import { WithCategory } from "../../types"
import { DetailsBoxContainer } from "./DetailsBoxContainer"
import { Image } from "../atoms/Image"
import { Text } from "../atoms/Text"
import { useMobileView } from "../../hooks"

interface ContractBoxProps extends WithCategory {
    unwrap: boolean
}

/** UI section displayed in Details view for information about contracts */
export const ContractBox: FC<ContractBoxProps> = ({
    unwrap,
    category,
    children
}): ReactElement => {
    const isMobileView = useMobileView()
    return (
        <DetailsBoxContainer type="contract" category={category}>
            <Image
                imageId="check"
                width={isMobileView ? "12vw":"9%"}
                position={{
                    x: isMobileView ? -5 : 0,
                    y: isMobileView ? 95 : 0,
                    invertX: true,
                    customTransform: [
                        isMobileView ? "-100%":"-40%", 
                        isMobileView ? "-100%":"10%"
                    ]
                }}
            />
            {
                unwrap ? children : (
                    <Text inContractBox>
                        {children}
                    </Text>
                )
            }
        </DetailsBoxContainer>
    )}