import { css } from "styled-components"

export interface _PositionAbsolute {
    /** Element position in `x` axis starting to left */
    x: number|string
    y: number|string
    invertX?: boolean
    invertY?: boolean
    centerTransform?: boolean
    customTransform?: [x: string, y: string]
    i?: boolean
}

/** 
 * Sets the element transform to its bottom center
 * & positions it according to given values
 * 
 * @param x Element position in `x` axis starting from left
 * @param y Element position in `y` axis starting from top
 * @param invertX If set to `true` `x` x starts from right
 * @param invertY If set to `true` `y` y starts from bottom
 * @param centerTransform If set to true, element transform is set to its center instead of its bottom center  
 * @param customTransform If set, sets element transform to given values
 */
export const _position_absolute = ({
    x,
    y,
    invertX = false,
    invertY = false,
    centerTransform = false,
    customTransform = undefined,
    i = false

}: _PositionAbsolute) => {
    const getPos = (
        p: number|string
    ) => typeof p === "number" ? `${p}%`:p
    
    const xPos = `${invertX ? "right" : "left"}: ${getPos(x)}`
    const yPos = `${invertY ? "bottom" : "top"}: ${getPos(y)}`
    const transformValues = {
        x: customTransform ? customTransform[0] : "-50%",
        y: customTransform ? customTransform[1] : (
            centerTransform ? "-50%" : "-100%"
        ),
    }
    if (i) {
        transformValues.x = "0px"
        transformValues.y = "0px"
    }

    return css`
        transform: translate(${transformValues.x}, ${transformValues.y});
        position: absolute;
        ${xPos};
        ${yPos};
    `
}