import { FC, ReactElement } from "react"
import styled from "styled-components"
import { _flexbox, _font_size } from "../../styles/mixins"
import { breakpoint, theme } from "../../styles"
import { WithCategory } from "../../types"
import { NavLink } from "react-router-dom"

interface DetailsTypeButtonProps extends WithCategory {
    link: string,
    onClick?: () => void,
}

/** Nav link displayed in Details views */
export const DetailsTypeButton: FC<DetailsTypeButtonProps> = ({
    category,
    link,
    onClick,
    children,
}): ReactElement => {
    return (
        <HeadlineContainer>
            <Button
                to={link}
                category={category}
                activeClassName="currentLink"
                onClick={onClick}
            >
                {children}
            </Button>
        </HeadlineContainer>
    )
}

/** Styled components */

type ButtonProps = Pick<DetailsTypeButtonProps, "category">
const Button = styled(NavLink) <ButtonProps>`
    ${_flexbox()};
    background-color: ${props => theme[props.category].button};
    padding: 0 20px;
    height: 100%;
    ${_font_size(12)}; //TODO: Use defined constant

    @media (max-width: ${breakpoint}px) {
        padding: 3.4vw;
        ${_flexbox({ jc: "space-between" })}
        font-size: 2.3vw;
    }

    border-radius: 40px;
    text-transform: uppercase;
    word-spacing: 1px;
    white-space: nowrap;
    font-weight: normal;
    box-shadow: 1px 2px 5px grey;
    text-decoration: none;
    color: ${theme.text.general};

    &.currentLink {
        background-color: #f0f0f4;
        box-shadow: 
        inset -3px -3px 5px white,
        inset 1px 1px 5px #b6b6b6;
    }
`
const HeadlineContainer = styled.h3`
    display: inline-block;
    height: 75%;
    &:last-of-type {
        margin-right: 30px;
        @media (max-width: ${breakpoint}px) {
            margin-right: 0;
            width: 100%;
        }
    }
`
