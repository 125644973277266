import { useEffect, useState } from "react"
import { breakpoint } from "../styles"

/** Checks if current view is mobile view depending on screen width */
export function useMobileView(initial: boolean = false): boolean {
    const [isMobileView, setMobileView] = useState<boolean>(initial)

    useEffect(
        () => {
            function handleResize() {
                const width = window.innerWidth
                const newMobileView: boolean = width <= breakpoint
                if (newMobileView !== isMobileView) setMobileView(newMobileView)
            }

            window.addEventListener("resize", handleResize)
            handleResize()
            return () => {
                window.removeEventListener("resize", handleResize)
            }
        }, [isMobileView]
    )

    return isMobileView
}