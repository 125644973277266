import { CSSProperties, FC, ReactElement, useEffect, useRef, useState, } from "react"
import styled, { css, FlattenSimpleInterpolation } from "styled-components"
import { SpriteId } from "../../types"
import { getSpriteData } from "../../utils"
import { manageSpriteSteps } from "../../styles/anims"
import { _PositionAbsolute, _position_absolute } from "../../styles/mixins"
import { useAnimation } from "../../hooks"

export interface SpriteProps {
    position: _PositionAbsolute
    spriteId: SpriteId
    width: CSSProperties["width"]
    repeat: CSSProperties["animationIterationCount"]
    duration: CSSProperties["animationDuration"]
    z: CSSProperties["zIndex"]
    anim?: FlattenSimpleInterpolation
}

interface ImgProps extends Pick<SpriteProps, "width"|"position"|"repeat"|"duration"|"z"> {
    aspectRatio: number
    steps: number
    src: string
    animations: FlattenSimpleInterpolation
    widthPx: number
}

/** Displays an image using sprite animation */
export const Sprite: FC<SpriteProps> = ({
    spriteId,
    width,
    position,
    duration,
    repeat,
    anim,
    z = 10,
}): ReactElement => {
    const { src, alt, aspectRatio, spritesNb } = getSpriteData(spriteId)
    const imgRef = useRef<HTMLImageElement>(null)
    const [imgWidth, setImgWidth] = useState<number>(0)

    useEffect(
        () => {
            if(imgRef.current) setImgWidth(imgRef.current?.clientWidth)
            console.log(imgRef.current?.clientWidth)
        }, []
    )

    const animations = useAnimation(
        manageSpriteSteps({
            steps: spritesNb,
            duration: duration,
            repeat: repeat,
        }),
        anim,
    )
    
    return (
        <Img
            ref={imgRef} 
            src={src} 
            alt={alt}
            width={width}
            widthPx={imgWidth}
            repeat={repeat}
            steps={spritesNb}
            duration={duration}
            position={position}
            aspectRatio={aspectRatio}
            animations={animations}
            z={z}
        />
    )
}

/** Styled components */

const Img = styled.img<ImgProps>`
    ${props => css`
        z-index: ${props.z};
        object-fit: cover;
        width: ${props.width};
        aspect-ratio: ${props.aspectRatio};
        height: calc(${props.widthPx}px /${props.aspectRatio});
        object-position: 0px ${(props.steps * 100) / (props.steps)}%;
        ${_position_absolute(props.position)};
        ${props.animations};
    `}
`
