import styled from "styled-components"
import { FC, ReactElement } from "react"
import { theme } from "../../styles"
import { Headline } from "../atoms/Headline"

/** Header for section blocks in Details views */
export const DetailsBoxHeader: FC = ({ children }): ReactElement => {
    return (
        <Container>
            <HeaderSection>
                <Headline sub="h4" inDetailsBox>
                    {children}
                </Headline>
            </HeaderSection>
            <StyleBoxContainer>
                <StyleBoxProtrude />
                <StyleBoxInvisible />
            </StyleBoxContainer>
        </Container>
    )
}

/** Styled components */

const Container = styled.header`
    position: relative;
    left: -15px;
    max-width: 85%;
`
const HeaderSection = styled.hgroup`
    background-color: ${theme.detailsBox.primary};
    padding: 10px 20px 10px 30px;
    color: ${theme.text.detailsBox};
    width: fit-content;
`
const StyleBoxContainer = styled.div`
    height: 15px;
    display: flex;
`
const StyleBoxProtrude = styled.div`
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 0;
    border-color: transparent ${theme.detailsBox.secondary} transparent transparent;
`
const StyleBoxInvisible = styled.div`background-color: transparent;`