import { css, CSSProperties, keyframes } from "styled-components"

const sparkling = keyframes`
      0% { opacity: 0; }
     20% { opacity: 0; }
     21% { opacity: 1; }
     24% { opacity: 1; }
     25% { opacity: 0; }
     30% { opacity: 0; }
     31% { opacity: 1; }
     35% { opacity: 1; }
     60% { opacity: 0; }
    100% { opacity: 0; }
`


export const lightningThunder = (
    duration: CSSProperties["animationDuration"] = "8s",
) => css`animation: ${sparkling} ${duration} linear infinite;`