import { FC, ReactElement } from "react"
import styled from "styled-components"
import { WithCategory, ImageId } from "../../types"
import { breakpoint, theme } from "../../styles"

import { Quote } from "../atoms/Quote"
import { DoubleQuoteIcon as QuoteIcon } from "../atoms/DoubleQuoteIcon"
import { Image } from "../atoms/Image"
import { useMobileView } from "../../hooks"

interface QuoteSectionProps extends WithCategory {
    quotes: string[]
    imageId: ImageId
    width?: string
    smWidth?: string
}

/** Header of Detail views which displayes a quote  */
export const QuoteSection: FC<QuoteSectionProps> = ({
    quotes,
    category,
    imageId,
    width = "38%",
    smWidth = "28vw",
}): ReactElement => { 
    const isMobileView = useMobileView()
    return (
        <Container category={category}>
            <QuoteIcon category={category} />
            {
                quotes.map(quote => (
                    <Quote key={quote} color={theme[category].quote}>{quote}</Quote>
                ))
            }
            <QuoteIcon category={category} isEnd inline />
            <Image
                imageId={imageId}
                width={isMobileView ? smWidth : width}
                position={{
                    x: 100,
                    y: 103,
                    customTransform: ["-100%", "-100%"]
                }}
            />
        </Container>
    )
}
/** Styled components */

const Container = styled.header<Pick<QuoteSectionProps, "category">>`
    padding-bottom: 2.8%;
    padding-left: 2.8%;
    margin-bottom: 2.8%;
    border-bottom: 1px solid ${props => theme[props.category].quote};
    position: relative;
 
    @media (max-width: ${breakpoint}px) {
        padding-bottom: 12vw;
    }

`