import { FC, ReactElement, Fragment } from "react"
import { Main, MainProps } from "./Main"
import { Footer } from "../organisms/Footer"

export const PageLayout: FC<MainProps> = ({
    bgId,
    bgSm,
    smAr,
    styles,
    bubble = undefined,
    isDetails = false,
    children,
}): ReactElement => (
    <Fragment>
        <Main
            bgId={bgId}
            bgSm={bgSm}
            smAr={smAr}
            bubble={bubble}
            isDetails={isDetails}
            styles={styles}
        >
            { children }
        </Main>
        <Footer/>
    </Fragment>
)