export const COMMON_LOCALES = {
    headline: "À quels risques automobiles\npouvez-vous être confrontés ?",
    crash: "Accidents",
    hazard: "Aléas",
    breakdown: "Panne",
    detailsHeadlines: {
        contract: "Que devez-vous vérifier sur votre contrat ?",
        datakeys: "Nos chiffres clés",
    },
    labels: {
        crash: {
            others: "Dommages causés aux autres",
            car: "Dommages matériels causés au véhicule",
            driver: "Dommages causés au conducteur",
        },
        hazard: {
            vandalism: "Vol, vandalisme, incendie",
            broken_glass: "Bris de glace",
            weather: "Tempête, grêle, catastrophes naturelles et technologiques",
        },
        breakdown: {
            puncture: "Panne mécanique et/ou crevaison",
        }

    },
    more: "En savoir plus",
    our_advice: "Nos conseils",
}