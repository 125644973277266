import { FC, Fragment, ReactElement, ReactNode, useState } from "react"
import styled from "styled-components"
import { ROUTES } from "../../_config/routes"
import { COMMON_LOCALES } from "../../_config/common_locales"

import { Category, NavButton, WithCategory, ImageId } from "../../types"
import { PageLayout } from "../templates/PageLayout"

import { AdviceItem } from "../atoms/AdviceItem"
import { DetailsWrapper } from "../atoms/DetailsWrapper"
import { Text } from "../atoms/Text"

import { AdviceHeader } from "../molecules/AdviceHeader"
import { AdviceButton } from "../molecules/AdviceButton"
import { QuoteSection } from "../molecules/QuoteSection"

import { ContractBox } from "../organisms/ContractBox"
import { DatakeysBox, DatakeysBoxProps } from "../organisms/DatakeysBox"
import { NavRibbon } from "../organisms/NavRibbon"

import { breakpoint, theme } from "../../styles"
import { _flexbox } from "../../styles/mixins"

export const DetailsTemplate: FC<DetailsTemplateProps> = ({
    advice,
    category,
    quote,
    datakeys,
    contract,
    label = "ta ronneda",
    unwrapContract = false,
}): ReactElement => {
    const [showAdvice, setShowAdvice] = useState<boolean>(false)
    const bubbleData = {
        category,
        position: {
            x: 22,
            y: 20,
            centerTransform: true
        }
    }

    const buttons: { [key in Category]: NavButton[] } = {
        crash: [
            {
                label: COMMON_LOCALES.labels.crash.others,
                link: ROUTES.CRASH.OTHERS,
            }, {
                label: COMMON_LOCALES.labels.crash.car,
                link: ROUTES.CRASH.CAR,
            }, {
                label: COMMON_LOCALES.labels.crash.driver,
                link: ROUTES.CRASH.DRIVER,
            },
        ],
        breakdown: [
            {
                label: COMMON_LOCALES.labels.breakdown.puncture,
                link: ROUTES.BREAKDOWN.PUNCTURE,
            }
        ],
        hazard: [
            {
                label: COMMON_LOCALES.labels.hazard.vandalism,
                link: ROUTES.HAZARD.VANDALISM,
            }, {
                label: COMMON_LOCALES.labels.hazard.broken_glass,
                link: ROUTES.HAZARD.BROKEN_GLASS,
            }, {
                label: COMMON_LOCALES.labels.hazard.weather,
                link: ROUTES.HAZARD.WEATHER,
            },
        ],
    }

    return (
        <PageLayout 
            isDetails
            bgId="bg_root" 
            bgSm="bg_sm_home" 
            smAr={0} 
            bubble={bubbleData} 
        >
            <NavRibbon
                category={category}
                buttons={buttons[category]}
            />
            <DetailsWrapper label={label} color={theme[category].balloon}>
                {
                    showAdvice ? (
                        <Fragment>
                            <AdviceHeader category={category} />
                            <Ol>
                                {
                                    advice.map((item, idx) => (
                                        <AdviceItem key={idx}
                                            counter={"advice-counter"}
                                            color={theme[category].advice}
                                        >
                                            <Text>{item}</Text>
                                        </AdviceItem>
                                    ))
                                }
                            </Ol>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <QuoteSection
                                quotes={quote.strings}
                                category={category}
                                imageId={quote.imageId}
                                width={quote.propWidth}
                                smWidth={quote.smWidth}
                            />
                            <DatakeysBox
                                category={category}
                                source={datakeys.source}
                                simple={datakeys.simple}
                                list={datakeys.list}
                                text={datakeys.textIfList}
                            >
                                {datakeys.textIfSimple}
                            </DatakeysBox>

                            <ContractBox category={category} unwrap={unwrapContract}>
                                {contract}
                            </ContractBox>
                        </Fragment>
                    )
                }

            </DetailsWrapper>
            {
                !showAdvice && (
                    <AdviceButton
                        category={category}
                        handleClick={
                            () => {
                                setShowAdvice(!showAdvice);
                                //@ts-ignore
                                OnAdvice();
                            }
                        }
                    />
                )
            }
        </PageLayout>
    )
}

export interface DetailsTemplateProps extends WithCategory {
    adviceLink?: string
    advice: ReactNode[]
    quote: {
        strings: string[]
        imageId: ImageId
        propWidth?: string
        smWidth?: string
    }
    unwrapContract?: boolean
    contract: ReactNode
    datakeys: {
        source: DatakeysBoxProps["source"]
        simple?: DatakeysBoxProps["simple"]
        textIfSimple?: ReactNode
        list?: DatakeysBoxProps["list"]
        textIfList?: DatakeysBoxProps["text"]
    },
    label?: string
}

/** Styled components */

const Ol = styled.ol`
    ${_flexbox()};
    padding: 100px 0px 30px 0px;
    gap: 15px;
    counter-reset: "advice-counter";
    
    @media (max-width: ${breakpoint}px) {
        ${_flexbox({fd: "column"})};
        padding: 0;
        margin: 10vw 0;
        gap: 10vw;
    }
`