import { CSSProperties } from "react"
import { css, keyframes } from "styled-components"

const movement = (from: string, to: string) => keyframes`
    from { transform: translate(${from}); }
    to { transform: translate(${to}); }
`

export const moveSprite = (
    from: string, 
    to: string, 
    duration: number, 
    repeat: CSSProperties["animationIterationCount"],
    timing: CSSProperties["animationTimingFunction"] = "ease",
) => css`
    animation: ${movement(from, to)} ${duration}s ${timing} ${repeat};
`
