import { Palette } from "../types"

export const redPalette: { [key in Palette]: string } = {
    thin: "#fde7ea",
    light: "#f27780",
    medium: "#ef5f6f",
    dark: "#7e1c3e",
}

export const orangePalette: { [key in Palette]: string } = {
    thin: "#fdf4e5",
    light: "#f5c072",
    medium: "#ef9c2a",
    dark: "#452c13",
}

export const bluePalette: { [key in Palette]: string } = {
    thin: "#ddf4f8",
    light: "#71d4f4", //! Added by me
    medium: "#55c6dd",
    dark: "#37abc3",
}

export const exoticPalette = {
    black: "#231f20",
    grey: "#6e6b6c",
    white: "#ffffff",
    beige: "#f0f0f4",
    darkPurple: "#444083"
}