import { css, CSSProperties, keyframes } from "styled-components"

const scaling = keyframes`
    0% {
        opacity: 0;
        transform: translate(0px, 42%) scale(0); 
    }
    25% {
        opacity: 1;
        transform: translate(0px, 28%) scale(.5); 
    }
    100% { 
        opacity: 0;
        display: block;
        transform: translate(0px, -9%) scale(1.5); 
    }
`

const sizing = keyframes`
      0% { opacity: 0; width: 0%; }
     33% { opacity: 1; width: 5%; }
    100% { opacity: 0; width: 15%; }
`


export const dispelSmoke = (
    delay: CSSProperties["animationDelay"] = "3s",
    duration: CSSProperties["animationDuration"] = "8s",
    bySize: boolean = true,
) => css`animation: ${bySize ? sizing:scaling} ${duration} linear ${delay} infinite;`