import { FC, ReactElement } from "react"
import { WithCategory } from "../../types";
import { SCENE_ROUTES } from "../../_config/routes";
import { NavButton } from "../atoms/NavButton";
import { Image } from "../atoms/Image";

/** UI nav button used to return on Scene view */
export const BackspaceButton: FC<WithCategory> = ({ category }): ReactElement => (
    <NavButton
        type="backspace"
        to={SCENE_ROUTES[category]}
        onClick={() => {
            //@ts-ignore
            OnBack();
        }}
    >
        <Image imageId="arrow_backspace" width="45%" />
    </NavButton>
)