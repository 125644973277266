import { FC, ReactElement } from "react"
import styled, { css } from "styled-components"
import { Text } from "../atoms/Text"
import { Strong } from "../atoms/Strong"
import { Image } from "../atoms/Image"
import { _flexbox, _font_size } from "../../styles/mixins"
import { ImageId } from "../../types"
import { breakpoint } from "../../styles"
import { useMobileView } from "../../hooks"

export interface DatakeyItemProps {
    imageId: ImageId
    percentage?: number
    digits?: string
    subject: string
    color: string
}

/** UI item displayed in datakeys list section in Details views */
export const DatakeyItem: FC<DatakeyItemProps> = ({
    color,
    imageId,
    subject,
    digits = undefined,
    percentage = undefined,
}): ReactElement => {
    const isMobileView = useMobileView()
    return (
        <Container percentage={percentage} color={color}>
            <Header percentage={percentage}>
                <Strong double={Boolean(percentage)} bigger={Boolean(digits)}>
                    {percentage ? percentage + "%" : digits}
                </Strong>
                <Image imageId={imageId} height={isMobileView ? "10vw":"60px"} />
            </Header>
            <Text>{subject}</Text>
        </Container>
    )
}

/** Styled components */
const Container = styled.li<Pick<DatakeyItemProps, "percentage" | "color">>`
    ${props => css`
        ${_flexbox({
            fd: "column",
            jc: "flex-start",
            ai: props.percentage ? "flex-start" : "center"
        })}
        flex: 1;
        align-self: stretch;
        ${_font_size(22)};
        padding: 0 2.8%;
        ${props.percentage && css`padding-right: 15px;`};
        position: relative;

        @media (max-width: ${breakpoint}px) {
            padding: 2.8vw 5vw;
            :first-child {
                padding-top: 0;
            }
        }

        :not(:first-child)::after {
            content: "";
            position: absolute;
            top: 30%;
            right: 100%;
            height: 50%;
            width: 1px;
            background-color: ${props.color};

            @media (max-width: ${breakpoint}px) {
                left: 5%;
                top: 0%;
                width: 90%;
                height: 1px;
            }
        }

        @media (max-width: ${breakpoint}px) {
            font-size: 3.8vw;
        }
    `}
`
const Header = styled.header<Pick<DatakeyItemProps, "percentage">>`
    ${props => css`
        ${props.percentage && css`padding-bottom: 3%;`}
        ${!props.percentage && css`gap: 10px;`}
        ${_flexbox({
            fd: props.percentage ? "row" : "column-reverse",
            ai: props.percentage ? "flex-end" : "center"
        })}
    `}
`
